<script>
import ProductCard from './product-card.vue'
import ProductLink from './product-link.vue'
import AreaLink from './area-link.vue'

export default {
  components: {
    ProductCard,
    ProductLink,
    AreaLink
  },
  // mixins: [],
  props: {
    // For Blog Show Page Only
    blog: {
      type: Object,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    // For Blog Show Page Only
    blogCategoryNames() {
      if (this.blog && this.blog.isDataLoaded()) {
        return this.blog.category_ids.map((id) => {
          return {
            name: this.$store.getters['categorySystems/findCategory'](id).name,
            id: this.$store.getters['categorySystems/findCategory'](id).id
          }
        })
      } else {
        return []
      }
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
