export default {
  props: {
    product: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: false
    },

    listLocation: {
      type: String,
      required: false
    }
  }
}
