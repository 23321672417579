<template lang="pug">
section.vc-blog-hot-articles(v-if="hasData")
  .blog-section-header
    .odd-container.has-padding
      h2.section-title.-larger {{ copyLocaleText('latest_blog') }}
      //- .tools-wrapper
  .blog-section-body
    b-loading(:active.sync="isLoading")

    .odd-container.has-padding
      .half
        blog-card.large(
          v-if="blog"
          :blog="blog"
        )
      .half
        blog-card.small(
          v-for="article in promotionalArticles"
          :key="article.id"
          :blog="article"
          :use-blog-link="false"
        )
</template>

<script>
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard
  },

  // mixins: [],

  props: {
    blogsCount: {
      type: Number,
      required: false,
      default: () => {
        return 1
      }
    },

    articlesCount: {
      type: Number,
      required: false,
      default: () => {
        return 3
      }
    }
  },

  data() {
    return {
      blogIds: [],
      promotionalArticlesIds: [],
      isLoading: true
    }
  },

  computed: {
    hasData() {
      return this.blog || this.promotionalArticles.length > 0
    },

    blog() {
      return this.$store.getters['promotionalArticles/all'].find(
        (blog) => this.blogIds[0] === blog.id
      )
    },

    promotionalArticles() {
      return this.$store.getters['promotionalArticles/all'].filter(
        (promotionalArticle) =>
          this.promotionalArticlesIds.includes(promotionalArticle.id)
      )
    }
  },

  // created() {},

  mounted() {
    this._fetchHotArticles()
  },

  methods: {
    async _fetchHotArticles() {
      await this._fetchLatestBlogs()
      await this._fetchLatestArticles()

      this.isLoading = false
    },

    async _fetchLatestBlogs() {
      const response = await this.$store.dispatch(
        'promotionalArticles/latestArticles',
        {
          pageNumber: 1,
          pageSize: this.blogsCount,
          sort: '-published_at',
          usage: 'blog',
          noReplace: true
        }
      )

      this.blogIds = response.data.data.map((blog) => blog.id)
    },

    async _fetchLatestArticles() {
      const response = await this.$store.dispatch(
        'promotionalArticles/latestArticles',
        {
          pageNumber: 1,
          pageSize: this.articlesCount,
          sort: '-published_at',
          usage: 'promotional_article',
          noReplace: true
        }
      )

      this.promotionalArticlesIds = response.data.data.map(
        (article) => article.id
      )
    }
  }
}
</script>
