import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    Countdown
  },

  // props: {},
  // data() {
  //   return {}
  // },

  computed: {
    countdownLabel() {
      if (this.salesEvent.isLive()) {
        return this.copyLocaleText('sales_event_end_remaining')
      } else {
        return this.copyLocaleText('sales_event_start_remaining')
      }
    },

    countdownTime() {
      const currentTime = this.currentUnixTime()
      let result = this.salesEvent.isLive()
        ? this.salesEvent.ended_at - currentTime
        : this.salesEvent.started_at - currentTime

      return result * 1000
    },

    countdownCopyKey() {
      if (this.salesEvent.isLive()) {
        return 'sales_event_will_end_in'
      } else {
        return 'sales_event_will_start_in'
      }
    },

    actionButtonIcon() {
      return {
        'fa-tags': !this.salesEvent.isLive(),
        'fa-shopping-cart': this.salesEvent.isLive()
      }
    },

    actionButtonText() {
      if (this.salesEvent.isLive()) {
        return this.copyLocaleText('get_sales_goods_now')
      } else {
        return this.actionLocaleText('get_info_for', {
          model: this.attributeLocaleText('sales_event', 'product_ships')
        })
      }
    }
  }

  // methods: {}
}
