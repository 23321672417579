import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_common_addresses',
  attributes: ['id', 'recipient', 'phone', 'company', 'ein', 'address'],
  editableAttributes: ['recipient', 'phone', 'company', 'ein', 'address']
}

export default class UserCommonAddress extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  fullAddress() {
    return `${this.address.zip} ${this.address.city} ${this.address.area} ${this.address.street_address}`
  }

  hasCompanyInfo() {
    return this.company && this.ein
  }
}
