import debounce from 'lodash.debounce'

export default {
  // components: {},

  // props: {},

  data() {
    return {
      debounceShowBoxShadowOnScrollDown: null,
      debounceAutoHideNavbarAtScrollDown: null
    }
  },

  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    }
  },

  mounted() {
    this.debounceShowBoxShadowOnScrollDown = debounce(
      this.showBoxShadowOnScrollDown,
      100,
      {
        leading: true,
        trailing: true
      }
    )
    this.debounceAutoHideNavbarAtScrollDown = debounce(
      this.autoHideNavbarAtScrollDown,
      100,
      {
        leading: true,
        trailing: true
      }
    )

    window.addEventListener('scroll', this.debounceShowBoxShadowOnScrollDown)
    window.addEventListener('scroll', this.debounceAutoHideNavbarAtScrollDown)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceShowBoxShadowOnScrollUp)
    window.removeEventListener(
      'scroll',
      this.debounceAutoHideNavbarAtScrollDown
    )
    document.documentElement.removeAttribute('style')
  },

  methods: {
    autoHideNavbarAtScrollDown() {
      const currentScrollPosition = window.pageYOffset
      const navBar = this.$el

      if (
        currentScrollPosition > this.prevScrollPosition &&
        currentScrollPosition > navBar.scrollHeight
      ) {
        if (this.mobileMenuStates['isMobileMenuActive']) return // dont hide menu if mobile menu activated

        navBar.classList.add('-hidden')
      } else {
        navBar.classList.remove('-hidden')
      }
      this.prevScrollPosition = currentScrollPosition
    },

    showBoxShadowOnScrollDown() {
      if (window.pageYOffset > 0) {
        this.$el.classList.add('has-shadow')
      } else {
        this.$el.classList.remove('has-shadow')
      }
    }
  }
}
