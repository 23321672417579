<template lang="pug">
.vc-notification-unit
  .notification-content
    .title 這裡是通知標題
    .info-wrapper
      span.tag.is-info 標籤名稱
      span.created-at 2018-10-20
    .summary
      span test message
  .notification-options
    .button.is-outlined.is-default 不再顯示
    .button.is-outlined.is-odd 了解更多
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
