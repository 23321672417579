<template lang="pug">
.vc-order-checkout-steps
  .odd-container
    .connect-line(:class="stepClasses")
    .step-unit(
      :class="stepActiveClasses(1)"
      @click="toStep(1)"
    )
      .name {{ copyLocaleText('checkout_flow.step_1_confirm_items') }}
      .dot
        .step-number 1
    .step-unit(
      :class="stepActiveClasses(2)"
      @click="toStep(2)"
    )
      .name {{ copyLocaleText('checkout_flow.step_2_fill_in_address_info') }}
      .dot
        .step-number 2
    .step-unit(
      :class="stepActiveClasses(3)"
      @click="toStep(3)"
    )
      .name {{ copyLocaleText('checkout_flow.step_3_complete_order') }}
      .dot
        .step-number 3
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    currentStep: {
      type: Number,
      required: true
    },

    originalStep: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isInTravelMode: false,
      initStep: null
    }
  },

  computed: {
    stepClasses() {
      return {
        'step-2': this.currentStep === 2,
        'step-3': this.currentStep === 3
      }
    }
  },
  // created() {},
  mounted() {
    // 紀錄了一開始近來頁面的 step 目前無作用
    this.initStep = this.currentStep
  },

  methods: {
    stepActiveClasses(stepNumber) {
      return {
        done: this.currentStep > stepNumber || this.originalStep >= stepNumber,
        active: this.currentStep === stepNumber
      }
    },

    toStep(step) {
      if (this.isInTravelMode) {
        this._goToStep(step)
      } else {
        this.isInTravelMode = true
        this.originalStep = this.currentStep
        this._goToStep(step)
      }
    },

    _goToStep(step) {
      if (step <= this.originalStep) this.$emit('update:currentStep', step)
    }
  }
}
</script>
