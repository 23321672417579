import flatten from 'lodash.flatten'

export default {
  // components: {},
  props: {
    promoterShare: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    _variantShipCompareTarget() {
      console.log(
        'please implement _variantShipCompareTarget computed property first. It must be an instance of Product or ProductVariant.'
      )
    },

    promoterEvent() {
      return (
        this.promoterShare &&
        this.$store.getters['promoterEvents/find'](this.promoterShare.event_id)
      )
    },

    eventVariantShips() {
      return flatten(
        this.promoterEvent.product_ships.map((productShip) => {
          return productShip.variant_ships.map((variantShip) => {
            return {
              id: variantShip.id,
              sku: variantShip.variant_sku,
              price: variantShip.price,
              variant_id: variantShip.variant_id
            }
          })
        })
      )
    },

    shareVariantShips() {
      return flatten(
        this.promoterShare.share_product_ships.map((shareProductShip) => {
          return shareProductShip.share_variant_ships
        })
      )
    },

    selectedEventVariantShipObject() {
      return this.eventVariantShips.find(
        (eventVariantShip) =>
          eventVariantShip.sku === this._variantShipCompareTarget.sku
      )
    },

    selectedShareVariantShip() {
      return this.shareVariantShips.find(
        (shareVariantShip) =>
          shareVariantShip.event_variant_ship_id ==
          this.selectedEventVariantShipObject.id
      )
    },

    variantShipPrice() {
      if (this.selectedEventVariantShipObject) {
        return this.toMoney(this.selectedEventVariantShipObject.price)
      } else {
        return this.toMoney(this._variantShipCompareTarget.consumer_price)
      }
    },

    discountRate() {
      let rateNumber = (this.variantShipPrice.cents / this.originalPriceCents)
        .toFixed(2)
        .toString()
        .split('.')[1]

      if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)
      return rateNumber
    },

    originalPriceCents() {
      return this.toMoney(this.product.original_price).cents
    },

    hasDiscountRate() {
      return this.variantShipPrice.cents < this.originalPriceCents
    }
  }
  // methods: {}
}
