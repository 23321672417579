import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'contents',
  attributes: [
    'id',
    'title',
    'content_category_id',
    'published_at',
    'description',
    'content',
    'cover',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'category_name',
    'category',
    'slug',
    'is_default',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'title',
    'published_at',
    'description',
    'content',
    'cover',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'category_id',
    'slug',
    'uploaded_attachment_ids'
  ]
}

export default class Content extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.usage) queryString += `&usage=${options.usage}`

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  // extra methods or helpers here...
}
