export default {
  // components: {},

  // mixins: [],

  // props: {},

  data() {
    return {
      lastScrollTop: 0
    }
  },

  computed: {
    isInInstagram() {
      return (window.navigator.userAgent || '')
        .toLowerCase()
        .includes('instagram')
    },

    isIphone() {
      return (window.navigator.userAgent || '').toLowerCase().includes('iphone')
    },

    instagramBarHeight() {
      return 36
    }
  },

  mounted() {
    this.isInInstagram && this.isIphone && this.listenScroll()
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollHandler)
  },

  methods: {
    listenScroll() {
      document.addEventListener('scroll', this.scrollHandler)
    },

    scrollHandler(event) {
      let scrollY = window.scrollY
      let bottomScrollY = document.getElementsByTagName('body')[0].clientHeight
      let htmlOffset = parseInt(
        window.getComputedStyle(document.getElementsByTagName('html')[0])[
          'padding-top'
        ]
      )
      if (scrollY > this.lastScrollTop) {
        this.onScrollDownForInstagram()
      } else {
        if (scrollY + window.innerHeight > bottomScrollY + htmlOffset - 10)
          return // prevent bottom bunce
        this.onScrollUpForInstagram()
      }
      this.lastScrollTop = scrollY
    }
  }
}
