import QueryForm from '../../components/promoter_event/check_order/query-form.vue'
import OrderList from '../../components/promoter_event/check_order/order-list.vue'

export default {
  name: 'promoter-event-check-order-show-page-container',

  components: {
    QueryForm,
    OrderList
  },

  mixins: [],

  // props: {},

  data() {
    return {
      promoterShareId: null,
      isLoaded: false,
      isShowOrders: false,
      orderIds: []
    }
  },

  computed: {
    promoterShare() {
      return this.$store.getters['promoterShares/find'](this.promoterShareId)
    },

    orders() {
      return this.$store.getters['orders/all']
    },

    isLoading() {
      return this.$store.getters['promoterShares/isLoading']
    }
  },

  // created() {},

  mounted() {
    this.promoterShareId = this.$el.dataset.promoterShare

    this.$store
      .dispatch('promoterShares/find', this.promoterShareId)
      .then((_) => {
        this.isLoaded = true
      })
  },

  watch: {},

  methods: {
    formSubmittedHandler(orderIds) {
      this.orderIds = orderIds
      this.isShowOrders = true
    }
  }
}
