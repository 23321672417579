<template lang="pug">
.vc-product-collect-button.option-button
  .button.is-default(
    @click="buttonClickedHandler"
    :class="{ 'is-collected': isCollected }"
  )
    .icon
      i.fa(:class="buttonIcon")
    span.text.short {{ buttonText.short }}
    span.text.long {{ buttonText.long }}
</template>

<script>
import ProductCollectService from '../../../../shared/services/product_collect_service.js'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  // components: {},
  // mixins: [],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    currentCollections() {
      return this.$store.getters['userCollections/currentCollections']
    },

    collectionsLatestUpdatedAt() {
      return this.$store.getters['userCollections/latestUpdatedAt']
    },

    isCollected() {
      return this.currentCollections.includes(this.product.id)
    },

    buttonIcon() {
      return {
        'fa-heart': this.isCollected,
        'fa-heart-o': !this.isCollected
      }
    },

    buttonText() {
      if (this.isCollected) {
        return {
          short: this.actionLocaleText('uncollect'),
          long: this.actionLocaleText('uncollect')
        }
      } else {
        return {
          short: this.actionLocaleText('collect'),
          long: this.actionLocaleText('collect_product')
        }
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    buttonClickedHandler() {
      if (this.isCollected) {
        this.removeProductFromCollection()
      } else {
        this.addProductToCollection()
      }
    },

    addProductToCollection() {
      const service = new ProductCollectService(
        this.currentUser.type,
        this.currentCollections,
        this.$vlf,
        this.$store
      )

      service
        .collect(this.product)
        .then(() => {
          return this.$vlf.setItem(
            ProductCollectService.collectionLatestUpdatedAtKey(),
            this.collectionsLatestUpdatedAt
          )
        })
        .then(() => {
          this.$store.dispatch('pixelOperation', [
            [
              'track',
              'AddToWishlist',
              {
                content_ids: this.product.sku,
                content_name: this.product.name,
                content_type: 'Product',
                currency: 'TWD',
                value: this.toMoney(this.product.consumer_price).amount
              }
            ]
          ])
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('product_collected_successfully')
          ])
        })
        .then(() => {
          return this.$store.dispatch('ga4Operation', [
            generateGA4Events('add_to_wishlist', {
              item: this.product
            })
          ])
        })
    },

    removeProductFromCollection() {
      const service = new ProductCollectService(
        this.currentUser.type,
        this.currentCollections,
        this.$vlf,
        this.$store
      )

      service
        .uncollect(this.product)
        .then(() => {
          return this.$vlf.setItem(
            ProductCollectService.collectionLatestUpdatedAtKey(),
            this.collectionsLatestUpdatedAt
          )
        })
        .then(() => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText(
              'product_removed_from_collection_successfully'
            )
          ])
        })
    }
  }
}
</script>
