<template lang="pug">
.vc-order-cart-item-list
  .order-items-container
    .list-header
      .label-part.main
        .label
          .icon
            i.fa.fa-list
          span {{ copyLocaleText('order_info') }}
      .label-part.info.unit-price
        .label
          span {{ attributeLocaleText('order/item', 'price') }}
      .label-part.info.quantity
        .label
          span {{ copyLocaleText('purchase_quantity') }}
      .label-part.info.price
        .label
          span {{ copyLocaleText('price') }}

    cart-item(
      v-for="(cartItem, index) in cartItems"
      :key="cartItem.id"
      :index="index"
      :cart-item="cartItem"
      :cart-service="cartService"
      :is-confirmed="isConfirmed"
    )

    .list-header(v-if="giftItems.length > 0")
      .label-part.main.gift
        .label
          .icon
            i.fa.fa-gift
          span {{ copyLocaleText('gift_info') }}

    cart-item(
      v-for="(giftItem, index) in giftItems"
      :key="giftItem.id"
      :index="index"
      :cart-item="giftItem"
      :cart-service="cartService"
      :isConfirmed="true"
    )
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItem from './cart-item.vue'

export default {
  components: {
    CartItem
  },

  mixins: [checkoutFlowMixin],

  props: {
    currentStep: {
      required: false
    },

    giftItems: {
      type: Array,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isConfirmed() {
      if (this.currentStep && this.currentStep === 1) return false
      return ['confirmed', 'completed', 'canceled'].includes(
        this.order.order_state
      )
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
