import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'shipping_methods',
  attributes: [
    'id',
    'name',
    'enabled',
    'rate_type',
    'rate_rule',
    'is_default',
    'shipping_categories'
  ],
  editableAttributes: [
    'name',
    'enabled',
    'rate_type',
    'rate_rule',
    'shipping_category_ids'
  ]
}

export default class ShippingMethod extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }

  static fetchRateTypes() {
    return axios.get(`${new this().apiBasePath()}/rate_types`)
  }

  // extra methods or helpers here...
  static rateRuleTemplate(rateType) {
    switch (rateType) {
      case 'flat':
        return {
          price: ''
        }
      case 'flat_per_item':
        return {
          price: ''
        }
    }
  }

  displayRateRule() {
    return I18n.t(
      `copies.shipping_fee_rate_rules.${this.rate_type}`,
      this.rate_rule
    )
  }
}
