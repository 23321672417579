<template lang="pug">
.vc-top-notification-broadcast-base
  swiper(
    :options="swiperOption"
    v-if="hasNotifications"
    @slide-change-transition-end="__setHeightForEverything"
  )
    swiper-slide(
      v-for="notification in notificationQueue"
      :key="notification.id"
    )
      template(v-if="notification.link_url")
        a.content.swiper-no-swiping(
          :href="notification.link_url"
          :target="notification.is_open_link_in_new_window ? '_blank' : '_self'"
        )
          span.text {{ notification.content }}
      template(v-else)
        span.content.swiper-no-swiping
          span.text {{ notification.content }}
      button.dismiss-button(@click="dismissNotification(notification)")
        i.fa.fa-close
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },

  //mixins: [],

  // props: {},
  data() {
    return {
      initialized: false,
      notifications: [],
      dismissedIds: [],
      swiperOption: {
        preventClicks: true,
        autoHeight: true,
        autoplay: {
          delay: 6500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }
      }
    }
  },
  computed: {
    hasNotifications() {
      if (!this.initialized) return false

      return this.notificationQueue.length > 0
    },

    notificationQueue() {
      return this.notifications.filter(
        (notification) => !this.dismissedIds.includes(notification.id)
      )
    }
  },

  watch: {
    hasNotifications: {
      handler(newValue) {
        if (newValue === true) {
          this.__setHeightForEverything()
        } else {
          const htmlElement = document.querySelector('html')
          const siteNavbar = document.querySelector('[data-navbar]')

          htmlElement.style.top = '0px'
          siteNavbar.style.transform = 'translateY(0px)'
        }
      }
    }
  },

  // created() {},
  async mounted() {
    // process raw JSON string from server-side rendered content
    this.notifications = JSON.parse(this.$el.dataset.notifications)
    await this.__init()
  },

  methods: {
    dismissNotification(notification) {
      if (!this.dismissedIds.includes(notification.id))
        this.dismissedIds.push(notification.id)
    },

    /**
     * 初始化公告機制
     * - 將 indexDB 中的 dismissed ids 放到 data
     * - 若當前的 week number 大於紀錄中的 week number，重置 dismissedIds，並重新紀錄 seenAt 為當前的 week number
     * @returns {Promise<void>}
     * @private
     */
    async __init() {
      this.initialized = true
      this.__setHeightForEverything()
    },

    __setHeightForEverything() {
      const htmlElement = document.querySelector('html')
      const siteNavbar = document.querySelector('[data-navbar]')

      this.$nextTick((_) => {
        htmlElement.style.top = `${this.$el.offsetHeight}px`
        siteNavbar.style.transform = `translateY(${this.$el.offsetHeight}px)`
      })
    }
  }
}
</script>
