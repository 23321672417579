export default {
  TWD: {
    iso_code: 'TWD',
    name: 'New Taiwan Dollar',
    symbol: '$',
    disambiguate_symbol: 'NT$',
    subunit: 'Cent',
    subunit_to_unit: 100,
    symbol_first: true,
    smallest_denomination: 1
  }
}
