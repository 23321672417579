<template lang="pug">
section.vc-blog-category-blog-section(v-if="blogs.length > 0")
  .blog-section-header
    .odd-container.has-padding
      h2.section-title.-larger
        .icon
          img(:src="icon")
        .en Babynews
        .zh {{ sectionTitle }}
      //- .tools-wrapper
  .blog-section-body
    b-loading(:active.sync="isLoading")

    .odd-container.has-padding
      .half
        blog-card.large(
          v-if="firstBlog"
          :key="firstBlog.id"
          :blog="firstBlog"
        )
      .half
        blog-card.small(
          v-for="blog in sideBlogs"
          :key="blog.id"
          :blog="blog"
        )

      .options-wrapper
        a.button.is-odd(href="/babynews")
          span
            | {{ actionLocaleText('more', { model: copyLocaleText('blogs') }) }}
          .icon
            i.fa.fa-chevron-right
</template>

<script>
import squaresTag from '../../../images/squares.svg'
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard
  },

  // mixins: [],

  props: {
    category: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      blogIds: null,
      isLoading: true
    }
  },

  computed: {
    isAtHomepage() {
      return window.location.pathname === '/'
    },

    sectionTitle() {
      if (this.category) {
        return this.category.name
      } else if (this.isAtHomepage) {
        return this.copyLocaleText('blogs')
      } else {
        return this.copyLocaleText('latest_blog')
      }
    },

    blogs() {
      if (this.blogIds) {
        return this.$store.getters['promotionalArticles/all']
      } else {
        return []
      }
    },

    firstBlog() {
      return this.$store.getters['promotionalArticles/all'].find(
        (blog) => this.blogIds[0] === blog.id
      )
    },

    sideBlogs() {
      return this.$store.getters['promotionalArticles/all'].filter((blog) =>
        this.blogIds.slice(1, 4).includes(blog.id)
      )
    },

    icon() {
      return squaresTag
    }
  },

  created() {
    if (this.category) {
      this._fetchArticlesByCategory()
    } else {
      this._fetchLatestArticles()
    }
  },

  // mounted() {},

  methods: {
    _fetchLatestArticles() {
      this.$store
        .dispatch('promotionalArticles/latestArticles', {
          pageNumber: 1,
          pageSize: 4,
          sort: '-published_at',
          usage: 'blog'
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
          this.isLoading = false
        })
    },

    _fetchArticlesByCategory() {
      this.$store
        .dispatch('categorySystems/fetchCategoryPromotionalArticles', {
          model: this.category,
          options: {
            pageNumber: 1,
            pageSize: 4,
            sort: '-view_count',
            noReplace: true,
            sub_categories_included: true
          }
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
          this.isLoading = false
        })
    }
  }
}
</script>
