<template lang="pug">
.vc-brand-summary-unit
  a.inner-wrapper(:href="brandPath")
    .logo-box
      .placeholder
        img.logo(v-lazy="logoUrl")
    .info-wrapper
      h3.title {{ brand.name }}
      .summary-wrapper
        span {{ brand.introduce }}
</template>

<script>
import brandBasicMixins from '../mixins/brand_basic_mixin.js'

export default {
  // components: {},
  mixins: [brandBasicMixins]

  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
