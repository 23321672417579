import debounce from 'lodash.debounce'
import queryString from 'query-string'
import MobileMenu from '../components/common/mobile-menu.vue'
import MemberOptionButton from '../components/common/member-options-button.vue'
import MemberCartButton from '../components/common/member-cart-button.vue'
import DebounceHideMixin from '../components/mixins/debounce_hide_mixin.js'

const MOBILE_MENU_BREAK_POINT = 769

export default {
  name: 'site-navbar-container',

  components: {
    MobileMenu,
    MemberOptionButton,
    MemberCartButton
  },

  mixins: [DebounceHideMixin],

  // props: {},

  data() {
    return {
      prevScrollPosition: 0,
      isSearchFormActive: false,
      searchText: undefined
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_MENU_BREAK_POINT
      )
    },

    menuTriggerClass() {
      return {
        'is-active': this.mobileMenuStates['isMobileMenuActive'],
        'is-child-level': this.isChildLevel
      }
    },

    isChildLevel() {
      let mobileMenuStates = this.mobileMenuStates

      return mobileMenuStates['isSkippingRootMenuToClose']
        ? !!mobileMenuStates['selectedTopLevelCategoryId']
        : mobileMenuStates['isCategoriesSelectorActive']
    },

    searchFormClass() {
      return {
        'is-active': this.isSearchFormActive
      }
    }
  },

  // created() {},

  //mounted() {},

  methods: {
    mobileMenuTriggerHandler() {
      if (this.isChildLevel) return this.prevButtonClickedHandler()

      let mobileMenuStates = this.mobileMenuStates

      // 關閉 或從 nav 打開 menu 時，
      // isSkippingRootMenuToClose 應為 false, 防止從 NavBar 進到 category list 時返回卻直接關掉 Menu;
      // isCategoriesSelectorActive 應為 false, 讓 menuTriggerClass 保持在正確的狀態
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(mobileMenuStates, {
          isMobileMenuActive: !mobileMenuStates['isMobileMenuActive'],
          isCategoriesSelectorActive: false,
          isSkippingRootMenuToClose: false
        })
      )
    },

    prevButtonClickedHandler() {
      let menuStates = Object.assign(this.mobileMenuStates)

      this.mobileMenuStates['selectedTopLevelCategoryId']
        ? (menuStates['selectedTopLevelCategoryId'] = null)
        : (menuStates['isCategoriesSelectorActive'] = false)

      this.$nextTick(() => {
        this.$store.dispatch('toggleMobileMenu', menuStates)
      })
    },

    toggleSearchForm() {
      this.isSearchFormActive = !this.isSearchFormActive
    },

    searchProduct() {
      this.$store
        .dispatch('pixelOperation', [['track', 'Search']])
        .then(() => {
          return this.$store.dispatch('ga4Operation', [
            ['event', 'search', { search_term: this.searchText }]
          ])
        })
        .then((_) => {
          Turbolinks.visit(
            encodeURI(
              `/products?q[variants_with_master_sku_or_brand_name_or_name_cont]=${this.searchText}`
            )
          )
        })
    },

    _restoreSearchTextFromUrl() {
      const options = queryString.parse(window.location.search)

      this.searchText =
        options['q[variants_with_master_sku_or_brand_name_or_name_cont]']
    }
  }
}
