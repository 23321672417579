<template lang="pug">
.vc-product-comments
  .fb-comments(
    :data-href="currentUrl"
    data-width="100%"
  )
</template>

<script>
import fbSdk from '../mixins/fb-sdk.js'

export default {
  // components: {},
  mixins: [fbSdk],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    currentUrl() {
      return window.location.href
    }
  },
  // created() {},
  mounted() {
    this.initFacebookSDK()
  }
  // methods: {}
}
</script>
