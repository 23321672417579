<template lang="pug">
span.vc-promotional-article-product-link
  span.raw-data(ref="raw-data")
    slot
  template(v-if="innerText")
    a.product-link(
      @click="showVariantsSelector"
      @mouseover="showPreviewBox"
      @mouseleave="hidePreviewBox"
    )
      .icon
        i.fa.fa-shopping-bag
      span.inner-text {{ innerText }}
    .preview-box(ref="preview-box")
      b-loading(
        :active.sync="isLoading"
        :is-full-page="false"
      )
      img.picture(:src="actuallyProductInfo.productCover")
      .product-info
        .brand
          span {{ actuallyProductInfo.productBrandName }}
        .name
          span {{ actuallyProductInfo.productName }}
</template>

<script>
import VariantsSelector from '../product/variants-selector.vue'
import SalesEventVariantsSelector from '../sales_event/variants-selector.vue'
import PromoterEventVariantsSelector from '../promoter_event/variants-selector.vue'

const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  // components: {
  //   VariantsSelector
  // },
  // mixins: [],
  props: {
    productId: {
      type: String,
      required: true
    },
    productSlug: {
      type: String,
      required: true
    },
    productCover: {
      type: String,
      required: true
    },
    productSku: {
      type: String,
      required: true
    },
    productBrandName: {
      type: String,
      required: true
    },
    productName: {
      type: String,
      required: true
    },
    salesEventId: {
      type: String,
      required: false
    },
    promoterShareId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      innerText: undefined,
      isLoading: true,
      isNotFound: false,
      apiCallCount: 0
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    previewBoxHeight() {
      return this.$refs['preview-box'].offsetHeight
    },

    product() {
      return this.$store.getters['products/find'](this.productId)
    },

    actuallyProductInfo() {
      return {
        productBrandName: this.product.brand_name || this.productBrandName,
        productName: this.product.name || this.productName,
        productCover: this.product.cover?.thumb?.url || this.productCover
      }
    },

    exceptionMessage() {
      if (this.salesEvent.isDataLoaded() && !this.salesEvent.isLive())
        return this.messageLocaleText('help.sales_event_not_live_currently')
      if (this.product.is_sold_out)
        return this.messageLocaleText(
          'help.sorry_this_product_sold_out_currentlly'
        )
      if (this.isNotFound) return '商品目前無法選購'
    },

    variantSelector() {
      if (this.salesEventId) {
        return SalesEventVariantsSelector
      } else if (this.promoterShareId) {
        return PromoterEventVariantsSelector
      } else {
        return VariantsSelector
      }
    },

    salesEvent() {
      return this.$store.getters['salesEvents/find'](this.salesEventId)
    },

    promoterShare() {
      return this.$store.getters['promoterShares/find'](this.promoterShareId)
    },

    promoterEvent() {
      return (
        this.promoterShare &&
        this.$store.getters['promoterEvents/find'](this.promoterShare.event_id)
      )
    }
  },

  // created() {},
  mounted() {
    this.innerText = this.$el.innerText
    this.$refs['raw-data'].remove()
  },

  methods: {
    showPreviewBox() {
      if (this.isMobile) return

      setTimeout(() => {
        this.$refs['preview-box'].style.opacity = 1
        this.$refs['preview-box'].style.bottom = `130%`
      }, 150)

      this.__fetchProduct()
    },

    hidePreviewBox() {
      this.$refs['preview-box'].style.opacity = 0
      setTimeout(() => {
        this.$refs['preview-box'].removeAttribute('style')
      }, 300)
    },

    showVariantsSelector() {
      if (this.isLoading && !this.isMobile) return

      this.__fetchProduct().then((_) => {
        if (this.exceptionMessage) return this.showMessage()

        this.$buefy.modal.open({
          parent: this,
          width: 380,
          component: this.variantSelector,
          props: {
            product: this.product,
            salesEvent: this.salesEvent,
            promoterShare: this.promoterShare
          }
        })
      })
    },

    showMessage() {
      this.$store.dispatch('addFlashMessage', ['notice', this.exceptionMessage])
    },

    __fetchProduct() {
      this.apiCallCount += 1

      if (this.apiCallCount > 1) return
      if (this.isNotFound) {
        this.apiCallCount = 0
        return Promise.resolve()
      }
      if (this.product.isDataLoaded()) {
        this.isLoading = false
        this.apiCallCount = 0
        return Promise.resolve()
      }

      if (this.salesEventId) {
        return this.$store
          .dispatch('salesEvents/find', this.salesEventId)
          .then((_) => {
            return this.$store.dispatch('salesEvents/findProduct', {
              model: this.salesEvent,
              productId: this.productId
            })
          })
          .catch((_) => {
            this.isNotFound = true
          })
          .finally((_) => {
            this.isLoading = false
            this.apiCallCount = 0
          })
      } else if (this.promoterShareId) {
        return this.$store
          .dispatch('promoterShares/find', this.promoterShareId)
          .then((_) => {
            return this.$store.dispatch('promoterEvents/findProduct', {
              model: this.promoterEvent,
              productId: this.productId
            })
          })
          .catch((_) => {
            this.isNotFound = true
          })
          .finally((_) => {
            this.isLoading = false
            this.apiCallCount = 0
          })
      } else {
        return this.$store
          .dispatch('products/find', this.productId)
          .catch((_) => {
            this.isNotFound = true
          })
          .finally((_) => {
            this.isLoading = false
            this.apiCallCount = 0
          })
      }
    }
  }
}
</script>
