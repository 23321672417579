<template lang="pug">
.vc-product-purchase-button.option-button
  .button.is-odd(
    @click="showVariantsSelector"
    :class="soldOutClass"
  )
    .icon
      i.fa.fa-shopping-cart
    template(v-if="product.is_sold_out")
      span.text {{ copyLocaleText('stock_status.no_stock') }}
    template(v-else)
      span.text.short {{ actionLocaleText('purchase') }}
      span.text.long {{ actionLocaleText('add_to_cart') }}
</template>

<script>
import VariantsSelector from './variants-selector.vue'

export default {
  components: {
    VariantsSelector
  },
  // mixins: [],
  props: {
    product: {
      type: Object,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    soldOutClass() {
      return {
        'is-sold-out': this.product.is_sold_out
      }
    }
  },

  // created() {},
  // mounted() {},
  methods: {
    showVariantsSelector() {
      if (this.product.is_sold_out) return this.showSoldOutMessage()

      this.$buefy.modal.open({
        parent: this,
        width: 380,
        component: VariantsSelector,
        props: {
          product: this.product
        }
      })
    },

    showSoldOutMessage() {
      this.$store.dispatch('addFlashMessage', [
        'notice',
        this.messageLocaleText('help.sorry_this_product_sold_out_currentlly')
      ])
    }
  }
}
</script>
