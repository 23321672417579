import PriceDetail from '../order/price-detail.vue'

export default {
  components: {
    PriceDetail
  },

  props: {
    order: {
      type: Object,
      required: true
    },

    cartItems: {
      type: Array,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    currentStep: {
      type: Number,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    isLoading() {
      return (
        this.$store.getters['users/isLoading'] ||
        this.$store.getters['orders/isLoading']
      )
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    shippingMethodFormOrderShipment() {
      if (this.order.shipments.length > 0)
        return this.$store.getters['orderShipments/find'](
          this.order.shipments[0].id
        ).shipping_method
    },

    paymentMethodFromOrderPayment() {
      if (this.order.payment)
        return this.$store.getters['orderPayments/find'](this.order.payment.id)
          .payment_method
    },

    paymentMethods() {
      return this.$store.getters['paymentMethods/allEnabled']
    },

    orderPayment() {
      return this.$store.getters['orderPayments/find'](this.order.payment.id)
    },

    orderInvoice() {
      return this.$store.getters['orders/findInvoice'](
        this.orderPayment.invoice.id
      )
    },

    creditNotes() {
      return this.$store.getters['orders/allCreditNotes']
    }
  },

  methods: {
    _tryFetchPaymentMethods() {
      if (this.paymentMethods.length === 0) {
        return this.$store.dispatch('paymentMethods/all')
      } else {
        return Promise.resolve(true)
      }
    },

    _scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },

    _trackCheckoutProgress() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:setAction',
            'checkout',
            {
              step: this.currentStep
            }
          ],
          [
            'send',
            {
              hitType: 'pageview',
              page: `/cart/step${this.currentStep}`
            }
          ]
        ])
        .then((_) => {
          const stepMapForPixel = [
            null,
            'InitiateCheckout', // Step 1
            'AddPaymentInfo', // Step 2
            null
          ]
          const operation = stepMapForPixel[this.currentStep]

          if (!operation) return
          this.$store.dispatch('pixelOperation', [
            [
              'track',
              operation,
              {
                currency: 'TWD',
                num_items: this.cartItems.length,
                value: this.toMoney(this.order.total).amount
              }
            ]
          ])
        })
    }
  }
}
