<template lang="pug">
.vc-product-card
  .inner
    a.product-image(
      :href="productLink"
      @click="sendGaClickEvent"
    )
      img.image(v-lazy="product.coverImageThumb()")
      .onsale-indicator(v-if="product.hasDiscount()")
        span.number {{ product.displayDiscountRate({ withUnit: false }) }}
        .unit {{ attributeLocaleText('product', 'discount_unit') }}
    .brand-name
      span.name(:title="product.brand_name") {{ product.brand_name }}
    .product-name
      a.inner-wrapper(
        :href="productLink"
        :title="product.name"
        @click="sendGaClickEvent"
      )
        h4.name {{ product.name }}
    .price-info
      //- if has no discount, this placeholder should hold up height.
      .price-type.placeholder
        span &nbsp;

      .price-type
        span.type {{ copyLocaleText('msrp') }}
        span.price {{ toMoney(product.original_price).format() }}
      .price-type
        span.type {{ copyLocaleText('discount_price') }}
        span.price {{ toMoney(product.consumer_price).format() }}
    .options-wrapper
      collect-button(:product="product")
      purchase-button(:product="product")
</template>

<script>
import productCardMixin from '../mixins/product_card_mixin.js'

export default {
  // components: {},
  mixins: [productCardMixin]
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {}
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
