import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_shipments',
  attributes: [
    'id',
    'tracking_code',
    'number',
    'cost',
    'shipped_at',
    'order',
    'address',
    'shipping_method',
    'shipment_items',
    'variants',
    'items',
    'state',
    'order_number',
    'adjustment_total',
    'promotion_total',
    'gateway_info',
    'total_fee',
    'created_at',
    'updated_at',
    'tallied_at',
    'returnable_limit_datetime'
  ],
  editableAttributes: ['tracking_code']
}

export default class OrderShipment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static tallyInBatches(shipmentIds = []) {
    if (shipmentIds.length <= 0) return

    const requestBody = {
      data: {
        type: 'tally_shipment_in_batches',
        attributes: {
          shipment_ids: shipmentIds
        }
      }
    }

    return axios.post(
      `${new this().apiBasePath()}/tally_in_batches`,
      requestBody
    )
  }

  tally() {
    return axios.post(`${this.apiBasePath()}/${this.id}/tally`)
  }

  ship() {
    const requestBody = {
      data: {
        type: 'order_shipment_ship',
        attributes: {
          tracking_code: this.tracking_code
        }
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/ship`, requestBody)
  }

  static existingMethodNames() {
    return axios.get(`${new this().apiBasePath()}/existing_method_names`)
  }

  // extra methods or helpers here...

  stateType() {
    return {
      'is-default': this.state === 'pending',
      'is-warning': this.state === 'ready',
      'is-info': this.state === 'tallying',
      'is-success': this.state === 'shipped',
      'is-danger': this.state === 'canceled'
    }
  }

  canTally() {
    return ['ready'].includes(this.state)
  }

  canShip() {
    return ['ready', 'tallying'].includes(this.state)
  }

  hasBackorderedItems() {
    return (
      this.shipment_items.filter((item) => item.state === 'backordered')
        .length > 0
    )
  }

  /**
   * @returns {boolean}
   */
  canApplyForRma() {
    const currentTime = Math.floor(new Date() / 1000)

    return currentTime < this.returnable_limit_datetime
  }

  fullAddress() {
    return `${this.address.address.zip} ${this.address.address.city} ${this.address.address.area} ${this.address.address.street_address}`
  }
}
