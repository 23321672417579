import queryString from 'query-string'
import RefundInfoForm from '../../components/order_payment/refund-info-form.vue'

export default {
  name: 'user-payment-refund-info-form',

  components: {
    RefundInfoForm
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      jwt: undefined
    }
  },

  computed: {
    isOrderLoaded() {
      return typeof this.order.id === 'string'
    },

    cartItems() {
      if (this.order.items) {
        return this.order.items.map((item) =>
          this.$store.getters['orderItems/find'](item.id)
        )
      } else {
        return []
      }
    }
  },

  mounted() {
    this.jwt = queryString.parse(window.location.search).token
  }

  // methods: {}
}
