<template lang="pug">
.vc-return-authorization-item-edit-record-unit
  .activity(
    v-html="messageLocaleText('return_authorization_management.admin_edited_amount_from_x_to_y', { admin: modelNameLocaleText('admin'), x: toMoney(record.parameters.original_amount).format(), y: toMoney(record.parameters.new_amount).format() })"
  )
  .edit-note
    .content(v-html="simpleFormat(record.parameters.edit_note)")
    .time(:title="dateTimeLocaleText(record.created_at)") — {{ timeAgoLocaleText(record.created_at) }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    record: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
