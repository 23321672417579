export const FETCH_ORDER_SHIPMENTS_SUCCESS = 'FETCH_ORDER_SHIPMENTS_SUCCESS'
export const GET_RELATED_ORDER_SHIPMENTS_SUCCESS =
  'GET_RELATED_ORDER_SHIPMENTS_SUCCESS'
export const GET_ORDER_SHIPMENT_SUCCESS = 'GET_ORDER_SHIPMENT_SUCCESS'
export const ADD_ORDER_SHIPMENT_SUCCESS = 'ADD_ORDER_SHIPMENT_SUCCESS'
export const UPDATE_ORDER_SHIPMENT_SUCCESS = 'UPDATE_ORDER_SHIPMENT_SUCCESS'
export const DELETE_ORDER_SHIPMENT_SUCCESS = 'DELETE_ORDER_SHIPMENT_SUCCESS'
export const GET_ORDER_SHIPMENT_EXISTING_METHOD_NAMES_SUCCESS =
  'GET_ORDER_SHIPMENT_EXISTING_METHOD_NAMES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
