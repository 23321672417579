import moment from 'moment'
import FormBase from 'odd-form_object'

export default class UserForm extends FormBase {
  constructor(model) {
    super(model)

    if (!!this.profile.birthday)
      this.profile.birthday = new Date(this.profile.birthday * 1000)
  }

  sync() {
    let editedModel = this.constructor.dataDumper(
      this,
      this.model,
      this.originalData
    )

    if (editedModel.profile.birthday)
      editedModel.profile.birthday = moment(editedModel.profile.birthday).unix()

    return editedModel
  }
}
