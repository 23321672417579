const PRESERVE_SYMBOLS_IN_SLUG = ['?']
const addEscape = (list) => {
  return list.map((item) => `\\${item}`)
}
const SYM_TABLE = {
  '?': '？'
}

export default class URIConvertService {
  /**
   * encode and normalize user input
   * @param {String} value
   * @returns {string}
   */
  static encode(value) {
    return encodeURIComponent(this.normalize(value))
  }

  /**
   * decode encoded slug
   * @param {String} value
   * @returns {string}
   */
  static decode(value) {
    return decodeURIComponent(value)
  }

  /**
   * simulate Ruby `URI.encode`
   * @param {string} value
   * @returns {string}
   */
  static simulateURIEncode(value) {
    return encodeURI(value)
  }

  /**
   * remove duplicated spaces, swap halfWidth `?` to fullWidth `？`
   * @param {string} value
   * @returns {string}
   */
  static normalize(value) {
    return value
      .replaceAll(/\s+/g, ' ')
      .replaceAll(
        new RegExp(`${addEscape(PRESERVE_SYMBOLS_IN_SLUG).join('')}`, 'g'),
        (match) => SYM_TABLE[match]
      )
  }
}

export { URIConvertService }
