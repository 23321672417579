<template lang="pug">
.vc-order-comment-form
  .form-header
    .logo-wrapper
      img.logo(:src="logoImage")
    .messages {{ copyLocaleText('order_comment_form_greeting_message') }}
  .form-body
    template(v-if="hasComments")
      comment-unit(
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
      )
    template(v-else)
      .empty-state
        .icon
          i.fa.fa-smile-o
        span {{ messageLocaleText('there_is_no_x_for_now', { x: modelNameLocaleText('comment') }) }}

  .form-footer
    .message-box
      b-input(
        type="textarea"
        :placeholder="messageLocaleText('help.leave_your_comment_here')"
        v-model="message"
        rows="2"
        @keydown.native.meta.enter="submitComment"
      )
    button.button.is-odd(@click="submitComment") {{ actionLocaleText('submit_comment') }}
</template>

<script>
import logoImage from '../../../images/logo-white.png'
import CommentUnit from '../comment/unit.vue'

export default {
  components: {
    CommentUnit
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    },

    jwtToken: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      message: ''
    }
  },

  computed: {
    logoImage() {
      return logoImage
    },

    comments() {
      return this.$store.getters['comments/all']
    },

    hasComments() {
      return this.comments.length > 0
    }
  },
  // created() {},
  mounted() {
    this.$store
      .dispatch('orders/fetchComments', {
        model: this.order,
        options: {
          sort: 'updated_at',
          token: this.jwtToken
        }
      })
      .then((_) => {
        this._setBodyMaxHeight()
        this._scrollToBottom()
      })
  },

  methods: {
    submitComment() {
      this.$store
        .dispatch('orders/addComment', {
          model: this.order,
          content: this.message,
          token: this.jwtToken
        })
        .then((_) => {
          return this._scrollToBottom()
        })
        .then((_) => {
          this.message = ''
        })
    },

    _setBodyMaxHeight() {
      const header = this.$el.querySelector('.form-header')
      const body = this.$el.querySelector('.form-body')
      const footer = this.$el.querySelector('.form-footer')

      body.style.maxHeight = `calc(100vh - 160px -
                              ${header.scrollHeight}px -
                              ${footer.scrollHeight}px)`
    },

    _scrollToBottom() {
      const body = this.$el.querySelector('.form-body')

      body.scrollTop = body.scrollHeight
    }
  }
}
</script>
