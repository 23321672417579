<template lang="pug">
.vc-promoter-event-check-order-order-list.odd-container.has-padding
  .odd-section-header
    .section-title
      | 查詢結果
    .help
      | {{ `共 ${totalCount} 筆訂單` }}
  .order-box(v-for="order in queriedOrders")
    .columns
      .column
        number-and-placed-date(:order="order")

        state-list(:order="order")

        .columns(v-for="(shipment, index) in orderShipmentsFor(order)")
          .column
            shipment-detail(
              :shipment="shipment"
              :index="index"
            )

        //- .columns
        //-   .column
        //-     .vc-order-option-buttons
        //-       comment-button(:order="order"
        //-                     class="is-alone")
</template>
<script>
import NumberAndPlacedDate from '../../order/number-and-placed-date.vue'
import StateList from '../../order/state-list.vue'
import ShipmentDetail from './order_list/shipment-detail.vue'
// import CommentButton from '../../order/comment-button.vue'

export default {
  components: {
    NumberAndPlacedDate,
    StateList,
    ShipmentDetail
  },

  props: {
    orderIds: {
      type: Array,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    orders() {
      return this.$store.getters['orders/all']
    },

    queriedOrders() {
      return this.orders.filter((order) => {
        return this.orderIds.includes(order.id)
      })
    },

    totalCount() {
      return this.orderIds.length
    }
  },

  mounted() {},

  methods: {
    orderShipmentsFor(order) {
      return order.shipments.map((shipment) => {
        return this.$store.getters['orderShipments/find'](shipment.id)
      })
    }
  }
}
</script>
