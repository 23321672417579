import ProductCollectService from '../../../shared/services/product_collect_service.js'
import RegisterBanner from '../components/register/banner.vue'

export default {
  name: 'register-container',

  components: {
    RegisterBanner
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      policyAgreed: false
    }
  },

  computed: {
    signUpDisabled() {
      return !this.policyAgreed
    }
  },

  // created() {},

  mounted() {
    this.$vlf
      .getItem(ProductCollectService.collectionKey())
      .then((localCollections) => {
        document.getElementById('local_collections').value =
          localCollections.join(',')
      })
  }

  // methods: {}
}
