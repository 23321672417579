<template lang="pug">
.vc-promotional-article-hot-articles(v-if="promotionalArticles.length > 0")
  .section-header
    .odd-container.has-padding
      h2.section-title
        .icon
          img(:src="icon")
        .en Select
        .zh {{ copyLocaleText('promotional_articles') }}
      //- .tools-wrapper
  .section-body
    .odd-container.has-padding
      b-loading(
        :active.sync="isLoading"
        :is-full-page="false"
      )
      .articles-wrapper.blog-section-body
        .odd-container
          .half
            blog-card.large(
              :blog="firstArticle"
              :use-blog-link="useBlogLink"
            )
          .half
            blog-card.small(
              v-for="(promotionalArticle, index) in sideArticles"
              :key="index"
              :blog="promotionalArticle"
              :use-blog-link="useBlogLink"
            )

      .options-wrapper
        a.button.is-odd(href="/promotional-articles")
          span
            | {{ actionLocaleText('more', { model: copyLocaleText('promotional_articles') }) }}
          .icon
            i.fa.fa-chevron-right
</template>

<script>
import squaresTag from '../../../images/squares.svg'
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard
  },
  // mixins: [],
  props: {
    articlesCount: {
      type: Number,
      required: false,
      default: () => {
        return 4
      }
    },

    useBlogLink: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },

  data() {
    return {
      promotionalArticlesIds: null,
      isLoading: true
    }
  },

  computed: {
    promotionalArticles() {
      if (this.promotionalArticlesIds) {
        return this.$store.getters['promotionalArticles/all'].filter(
          (promotionalArticle) =>
            this.promotionalArticlesIds.includes(promotionalArticle.id)
        )
      } else {
        return []
      }
    },

    firstArticle() {
      return this.$store.getters['promotionalArticles/all'].find(
        (article) => this.promotionalArticlesIds[0] === article.id
      )
    },

    sideArticles() {
      return this.$store.getters['promotionalArticles/all'].filter((article) =>
        this.promotionalArticlesIds.slice(1, 4).includes(article.id)
      )
    },

    icon() {
      return squaresTag
    }
  },

  created() {
    this.$store
      .dispatch('promotionalArticles/latestArticles', {
        pageNumber: 1,
        pageSize: this.articlesCount,
        sore: this.articlesCount,
        sort: '-published_at',
        usage: 'promotional_article',
        noReplace: true
      })
      .then((response) => {
        this.promotionalArticlesIds = response.data.data.map(
          (promotionalArticle) => promotionalArticle.id
        )
        this.isLoading = false
      })
  }
  // mounted() {},
  // methods: {}
}
</script>
