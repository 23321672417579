<template lang="pug">
.vc-order-shipping-and-payment-method.odd-container.has-padding
  b-loading(:active.sync="isLoading")

  .form-container
    .form-column.shipping-address-form
      //- shipping address
      address-form(
        :form="form"
        address-type="shipping"
        @form-edited="formEditedHandler"
      )

      //- billing address
      address-form(
        :form="form"
        address-type="billing"
        @form-edited="formEditedHandler"
      )

    .form-column.notes-and-aggrements
      //- shipping method
      .section-label
        span {{ modelNameLocaleText('shipping_method') }}
      .form-section
        shipping-method-selector(
          :selected-shipping-method-id="shippingMethodFormOrderShipment.id"
          :cart-items="cartItems"
          :is-confirmed="true"
        )

      //- payment method selector
      .section-label(:class="errors.errorClassAt('payment_method_id')")
        span {{ modelNameLocaleText('payment_method') }}
      .form-section(:class="errors.errorClassAt('payment_method_id')")
        payment-method-selector(
          :selected-payment-method-id.sync="form.payment_method_id"
          :paymentMethods="paymentMethods"
          @form-edited="formEditedHandler"
        )

      //- notes
      .section-label
        span {{ attributeLocaleText('order', 'notes') }}
      .form-section
        b-field(
          :type="errors.errorClassAt('notes')"
          :message="errors.get('notes')"
        )
          b-input(
            type="textarea"
            v-model="form.notes"
            @input="formEditedHandler(); errors.clear('notes')"
          )

      //- aggrements
      .section-label
        span {{ copyLocaleText('user_aggrement_and_notes') }}
      .form-section
        .field
          b-checkbox(
            v-model="form.is_agree_with_term_of_service"
            size="is-small"
            type="is-odd"
          )
            span(
              v-html="copyLocaleText('i_agree_with_terms_of_service_and_privacy_policy')"
            )
        //- .field(v-if="currentUser.isGuest()")
        //-   b-checkbox(v-model="form.is_agree_to_join_membership"
        //-              size="is-small"
        //-              type="is-odd") {{ copyLocaleText('join_membership_after_checkout') }}
        .field
          b-checkbox(
            v-model="form.is_agree_to_receive_news_letters"
            size="is-small"
            type="is-odd"
          ) {{ copyLocaleText('i_would_like_to_receive_newsletters') }}

      //- price detail
      .section-label
        span {{ attributeLocaleText('order', 'price_detail') }}
      .form-section
        price-detail(:order="order")

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(
      @click="placeOrder"
      :disabled="!form.is_agree_with_term_of_service"
      :class="{ 'is-loading': isLoading }"
    ) {{ actionLocaleText('next_step') }}
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import Form from 'odd-form_object'
import AddressForm from './address-form.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'
import PaymentMethodSelector from './payment-method-selector.vue'
import BackToStoreButton from './back-to-store-button.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    AddressForm,
    ShippingMethodSelector,
    PaymentMethodSelector,
    BackToStoreButton
  },

  mixins: [checkoutFlowMixin],
  // props: {},
  data() {
    return {
      isFormEdited: false,
      form: new Form(Object.assign(this.order, this.order.formTemplate()))
    }
  },

  // computed: {},

  watch: {
    'form.payment_method_id': {
      handler() {
        this.errors.clear('payment_method_id')
      }
    }
  },
  // created() {},
  mounted() {
    if (this.currentUser.isGuest())
      this.isAgreeToJoinMembershipAfterCheckout = true
    if (this.order.payment.id)
      this.form.payment_method_id = this.paymentMethodFromOrderPayment.id
    this.form.email = this.order.email
    this._tryFetchPaymentMethods()
    this._trackCheckoutProgress()
  },

  methods: {
    formEditedHandler() {
      this.isFormEdited = true
    },

    placeOrder() {
      if (!this.form.is_agree_with_term_of_service) return

      if (this.order.order_state === 'items_confirmed' || this.isFormEdited) {
        this.cartService
          .placeOrder(this.form.sync())
          .then(() => {
            this.$store.dispatch('ga4Operation', [
              generateGA4Events('add_shipping_info', {
                items: this.cartItems,
                variants: this.$store.getters['productVariants/all'],
                value: this.toMoney(this.order.total).amount
              })
            ])
          })
          .catch((errors) => {
            if (errors.response.status === 500)
              this.$emit('update:currentStep', 1)
          })
      } else {
        this.$emit('update:currentStep', 3)
      }

      this._scrollToTop()
    }
  }
}
</script>
