<template lang="pug">
section.vc-product-category-select-products
  .section-header
    .odd-container.has-padding
      h2.section-title
        .icon
          img(:src="squareIcon")
        .en select
        .zh {{ copyLocaleText('select_goods') }}
      //- .tools-wrapper
  .section-body
    .odd-container.has-padding
      .tabs-wrapper
        b-tabs(
          v-model="currentCategoryId"
          size="is-small"
          @input="fetchCategorySelectGoods"
        )
          b-tab-item(
            v-for="category in categories"
            :label="category.name"
            :key="category.id"
            :value="category.id"
          )

      b-loading(
        :active.sync="isLoading"
        :is-full-page="false"
      )

      product-card(
        v-for="(product, index) in selectGoods"
        :key="product.id"
        :product="product"
        :index="index"
        :list-location="listLocation"
        @ga-impressed="impressedHandler"
      )

      .options-wrapper
        a.button.is-odd(href="/products")
          span
            | {{ actionLocaleText('all', { model: modelNameLocaleText('product_category') }) }}
          .icon
            i.fa.fa-chevron-right
</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import squaresIcon from '../../../images/squares.svg'
import ProductCard from '../product/card.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    ProductCard
  },

  mixins: [productImpressionMixin],

  props: {
    productsCount: {
      type: Number,
      required: false,
      default: () => {
        return 8
      }
    }
  },

  data() {
    return {
      productIds: null,
      currentCategoryId: null,
      currentCategoryIndex: 0
    }
  },

  computed: {
    squareIcon() {
      return squaresIcon
    },

    categories() {
      return this.$store.getters['productCategories/allTopLevel']
    },

    currentCategory() {
      // return this.categories[this.currentCategoryIndex]

      return this.categories.find(
        (category) => category.id === this.currentCategoryId
      )
    },

    selectGoods() {
      if (!this.productIds) return []
      return this.$store.getters['products/all'].filter((product) =>
        this.productIds.includes(product.id)
      )
      // if (this.productIds)
      //   return this.$store.getters['products/all'].filter((product) =>
      //     this.productIds.includes(product.id)
      //   )
    },

    listLocation() {
      return `Select Goods - ${this.currentCategory.name}`
    },

    isLoading() {
      return this.$store.getters['productCategories/isLoading']
    }
  },

  async created() {
    await this.$store.dispatch('productCategories/all', {
      sort: 'position',
      search: {
        parent_id_null: true
      }
    })

    this.currentCategoryId = this.categories[0]?.id

    this.fetchCategorySelectGoods()
  },

  // mounted() {},
  methods: {
    fetchCategorySelectGoods() {
      if (!this.currentCategory) return

      return this.$store
        .dispatch('productCategories/selectGoods', {
          model: this.currentCategory,
          options: {
            pageSize: this.productsCount,
            pageNumber: 1
          }
        })
        .then((response) => {
          this.productIds = response.data.data.map((product) => product.id)
        })
        .then(() => {
          this.$store.dispatch('ga4Operation', [
            generateGA4Events('view_item_list', {
              items: this.selectGoods,
              item_list_name: this.listLocation
            })
          ])
        })
    }
  }
}
</script>
