<template lang="pug">
.vc-common-address-unit(
  :class="unitClasses"
  @click="addressSelected"
)
  .index-indicator
    span.index {{ index + 1 }}
  .address-info
    .info-section.recipient
      span.label {{ attributeLocaleText('user/common_address', 'recipient') }}
      span.data {{ address.recipient }}
    .info-section.phone
      span.label {{ attributeLocaleText('user/common_address', 'phone') }}
      span.data {{ address.phone }}
    .info-section.company(v-if="address.hasCompanyInfo()")
      span.label {{ attributeLocaleText('user/common_address', 'company') }}
      span.data {{ address.company }}
    .info-section.ein(v-if="address.hasCompanyInfo()")
      span.label {{ attributeLocaleText('user/common_address', 'ein') }}
      span.data {{ address.ein }}
    .info-section.address
      span.label {{ attributeLocaleText('user/common_address', 'address') }}
      span.data {{ address.fullAddress() }}
</template>

<script>
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'

export default {
  // components: {},
  // mixins: [],
  props: {
    address: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    },

    selectedAddress: {
      type: [Object, Number],
      required: false,
      default: () => {
        return 0
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isSelected() {
      return isEqual(this.address, this.selectedAddress)
    },

    unitClasses() {
      return {
        selected: this.isSelected
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    addressSelected() {
      this.$emit('address-selected', cloneDeep(this.address))
    }
  }
}
</script>
