import debounce from 'lodash.debounce'

export default {
  // components: {},

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    // 產品清單名稱。 e.g. 商品類別 - 外出用品
    listLocation() {
      // override this
    }
  },

  methods: {
    impressedHandler: debounce(function () {
      this.$store.dispatch('gaOperation', [
        [
          'send',
          'event',
          'Ecommerce',
          'Impressions',
          this.listLocation,
          {
            nonInteraction: true
          }
        ]
      ])
    }, 800)
  }
}
