import EditorContentWrapper from '../../components/promotional_article/editor-content-wrapper.vue'
import LatestBlogs from '../../components/blog/latest-blogs.vue'
import RelevantBlogs from '../../components/blog/relevant-blogs.vue'

export default {
  name: 'blog-show-page-container',

  components: {
    EditorContentWrapper,
    LatestBlogs,
    RelevantBlogs
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      blogId: null
    }
  },

  computed: {
    blog() {
      return this.$store.getters['promotionalArticles/find'](this.blogId)
    },

    hasBlog() {
      return typeof this.blog.id === 'string'
    }
  },

  // created() {},

  mounted() {
    this.blogId = this.$el.dataset.blog
    this.$store.dispatch('promotionalArticles/find', this.blogId)
  }

  // methods: {}
}
