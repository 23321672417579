<template lang="pug">
.vc-product-category-description-block(v-if="isShowable")
  .cover-photo
    img.image(:src="coverPhotoUrl")
  .content-wrapper
    .title
      .prefix 關於&nbsp;
      .name
        | {{ category.name }}
    .description(v-html="simpleFormat(category.description)")
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    coverPhotoUrl() {
      if (this.category.cover.url) {
        return this.category.cover.url
      } else {
        return 'https://via.placeholder.com/512x512?text=No+Image'
      }
    },

    isShowable() {
      return (
        (this.category.description && this.category.description !== '') ||
        !this.category.cover.url.includes('/via.placeholder.com/')
      )
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
