<template lang="pug">
.vc-sales-event-product-unit
  .inner
    a.product-image(@click="visitProductPage")
      img.image(v-lazy="product.coverImageThumb()")
      .onsale-indicator
        span.number {{ discountRate }}
        .unit {{ attributeLocaleText('product', 'discount_unit') }}
    .brand-name
      span.name(:title="product.brand_name") {{ product.brand_name }}
    .product-name
      a.inner-wrapper(
        :title="product.name"
        @click="visitProductPage"
      )
        h4.name {{ product.name }}
    .price-info
      //- if has no discount, this placeholder should hold up height.
      .price-type.placeholder
        span &nbsp;

      .price-type
        span.type {{ copyLocaleText('msrp') }}
        span.price {{ toMoney(product.original_price).format() }}
      .price-type.-event-live
        span.type {{ copyLocaleText('event_price') }}
        span.price {{ eventPrice.format() }}
    .options-wrapper(v-if="salesEvent.isLive()")
      purchase-button(
        :product="product"
        :sales-event="salesEvent"
      )
</template>

<script>
import productCardMixin from '../mixins/product_card_mixin.js'
import salesEventPricesMixin from '../mixins/sales_event_prices_mixin.js'
import PurchaseButton from './purchase-button.vue'

export default {
  components: {
    PurchaseButton
  },

  mixins: [productCardMixin, salesEventPricesMixin],

  // props: {},

  // data() {},

  computed: {
    discountRate() {
      let rateNumber = (this.eventPrice.cents / this.product.original_price)
        .toFixed(2)
        .toString()
        .split('.')[1]

      if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)
      return rateNumber
    },

    productLink() {
      const basicPath = this.salesEvent.is_hidden
        ? `/sales-events/s/${this.salesEvent.token}`
        : `/sales-events/${this.salesEvent.id}`

      return `${basicPath}/products/${this.product.slug || this.product.id}`
    },

    _eventPriceCompareTarget() {
      return this.product
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    visitProductPage() {
      this.sendGaClickEvent()
      if (this.salesEvent.isLive()) return Turbolinks.visit(this.productLink)

      this.$store.dispatch('addFlashMessage', [
        'notice',
        this.messageLocaleText('help.sales_event_not_live_currently')
      ])
    },

    sendGaImpressionEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addImpression',
            {
              id: this.product.sku,
              name: this.product.name,
              brand: this.product.brand_name,
              list: this.listLocation,
              position: this.index + 1,
              price: this.eventPrice.amount
            }
          ]
        ])
        .then((_) => {
          this.$emit('ga-impressed')
        })
    },

    sendGaClickEvent() {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addProduct',
          {
            id: this.product.sku,
            name: this.product.name,
            brand: this.product.brand_name,
            position: this.index + 1,
            price: this.eventPrice.amount
          }
        ],
        [
          'ec:setAction',
          'click',
          {
            list: this.listLocation
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'UX',
            eventAction: 'click',
            eventLabel: this.listLocation,
            transport: 'beacon'
          }
        ]
      ])
    }
  }
}
</script>
