<template lang="pug">
.vc-product-support-document-unit
  .basic-info
    .icon
      i.fa.fa-file-o(:class="fileIconType")
    .file-info
      .title {{ document.title }}
      .date {{ dateTimeLocaleText(document.created_at, 'YYYY-MM-DD') }}
  .options
    a.button.is-outlined.is-default(
      :href="document.file.url"
      target="_blank"
      rel="noopener"
      :download="document.original_filename"
    ) {{ actionLocaleText('download_file') }}
</template>

<script>
import FileTypeService from '../../../../shared/services/file_type_service.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    fileIconType() {
      return FileTypeService.call(this.document.type).icon
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
