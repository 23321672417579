export const FETCH_SHIPPING_METHODS_SUCCESS = 'FETCH_SHIPPING_METHODS_SUCCESS'
export const GET_RELATED_SHIPPING_METHODS_SUCCESS =
  'GET_RELATED_SHIPPING_METHODS_SUCCESS'
export const GET_SHIPPING_METHOD_SUCCESS = 'GET_SHIPPING_METHOD_SUCCESS'
export const ADD_SHIPPING_METHOD_SUCCESS = 'ADD_SHIPPING_METHOD_SUCCESS'
export const UPDATE_SHIPPING_METHOD_SUCCESS = 'UPDATE_SHIPPING_METHOD_SUCCESS'
export const DELETE_SHIPPING_METHOD_SUCCESS = 'DELETE_SHIPPING_METHOD_SUCCESS'
export const FETCH_RATE_TYPES_SUCCESS = 'FETCH_RATE_TYPES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
