<template lang="pug">
.vc-common-mobile-product-category-list.menu-wrapper
  b-loading(
    :active="isLoading"
    :is-full-page="false"
  )
  transition(
    enter-active-class="animated slideInLeft"
    leave-active-class="animated slideOutLeft"
  )
    ul.mobile-menu-items(v-if="!isSubCategoriesMenuShow")
      li.mobile-menu-item(
        v-for="topLevelCategory in topLevelCategories"
        :key="topLevelCategory.id"
      )
        a.link(
          href="#"
          @click.prevent="topLevelCategoryClickedHandler(topLevelCategory)"
        )
          span.text {{ topLevelCategory.name }}
          .icon(v-if="topLevelCategory.hasSubCategories()")
            i.fa.fa-chevron-right
  transition(
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutRight"
  )
    ul.mobile-menu-items(v-if="isSubCategoriesMenuShow")
      li.mobile-menu-item
        a.link.selected-top-level-category(
          :href="`/products?category=${selectedCategory.id}`"
        )
          span.text {{ selectedCategory.name }}
      li.mobile-menu-item(
        v-for="subCategory in subCategories"
        :key="subCategory.id"
      )
        a.link(:href="`/products?category=${subCategory.id}`")
          span.text {{ subCategory.name }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    isSubCategoriesMenuShow() {
      return (
        this.mobileMenuStates['isCategoriesSelectorActive'] &&
        this.mobileMenuStates['selectedTopLevelCategoryId'] !== null
      )
    },

    topLevelCategories() {
      return this.$store.getters['productCategories/allTopLevel']
    },

    selectedCategory() {
      return this.$store.getters['productCategories/find'](
        this.mobileMenuStates['selectedTopLevelCategoryId']
      )
    },

    subCategories() {
      return this.$store.getters['productCategories/all'].filter(
        (category) => String(category.parent_id) === this.selectedCategory.id
      )
    },

    isLoading() {
      return this.$store.getters['productCategories/isLoading']
    }
  },
  // created() {},
  mounted() {
    if (this.topLevelCategories.length > 0) return

    this.$store.dispatch('productCategories/all', {
      sort: 'position'
    })
  },

  methods: {
    topLevelCategoryClickedHandler(topLevelCategory) {
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, {
          selectedTopLevelCategoryId: topLevelCategory.id
        })
      )
    }
  }
}
</script>
