<template lang="pug">
.vc-dashboard-children-list
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  template(v-if="hasChildren")
    .child-wrapper(v-for="(child, index) in children")
      child-form(
        :child="child"
        :human-index="index + 1"
        display-mode
      )

  child-form(
    v-if="isAddingChild"
    :human-index="childrenCount + 1"
    @child-added="childAddedHandler"
    @form-canceled="childAddedHandler"
  )

  .message-wrapper(v-if="isCreatable")
    .message(v-if="!isAddingChild")
      | {{ formMessage }}
    button.button.is-odd.is-block(@click="addChild")
      | {{ actionLocaleText('add_new', { model: modelNameLocaleText('user/child') }) }}
</template>

<script>
import ChildForm from './child-form'

const MAX_CHILDREN_AMOUNT = 3

export default {
  components: {
    ChildForm
  },
  // mixins: [],
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isAddingChild: false
    }
  },

  computed: {
    formMessage() {
      if (this.hasChildren) {
        return this.copyLocaleText('children_add_remain_message', {
          amount: this.childreanCreatableAmount
        })
      } else {
        return this.copyLocaleText('children_add_message', {
          amount: this.maxChildrenAmount
        })
      }
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    children() {
      return this.$store.getters['users/allChildren']
    },

    maxChildrenAmount() {
      return MAX_CHILDREN_AMOUNT
    },

    childreanCreatableAmount() {
      return MAX_CHILDREN_AMOUNT - this.childrenCount
    },

    childrenCount() {
      return this.children.length
    },

    isCreatable() {
      return this.childrenCount < MAX_CHILDREN_AMOUNT
    },

    hasChildren() {
      return this.childrenCount > 0
    }
  },
  // created() {},
  mounted() {
    this.$store.dispatch('users/find', this.currentUser.id).then((_) => {
      this.$store.dispatch('users/fetchChildren', {
        model: this.user
      })
    })
  },

  methods: {
    addChild() {
      this.isAddingChild = true
    },

    childAddedHandler() {
      this.isAddingChild = false
    }
  }
}
</script>
