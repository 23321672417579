<template lang="pug">
.vc-brand-video-card
  .video-trigger
    .inner
      iframe.video(
        :src="`https://www.youtube.com/embed/${youtubeId}?rel=0&controls=1&showinfo=0`"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen=""
      )
      //- img.thumb(:src="`https://picsum.photos/800/800/?image=${Math.floor(Math.random() * Math.floor(85))}`")
  .title-box
    h4.title {{ title }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    title: {
      type: String,
      required: false,
      default: () => {
        return 'SHORT VIDEO FILE intro'
      }
    },

    youtubeUrl: {
      type: String,
      required: false,
      default: () => {
        return 'http://www.youtube.com/embed/f0xDfDTurFI?rel=0'
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    youtubeId() {
      const regEx =
        '^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?.com|youtu.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)'

      return this.youtubeUrl.match(regEx)[1]
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
