<template lang="pug">
.vc-order-detail-content
  b-loading(:active.sync="isLoading")

  template(v-if="isOrderLoaded")
    number-and-placed-date(:order="order")

    state-list(:order="order")

    shipment-detail(
      v-for="(shipment, index) in shipments"
      :key="shipment.id"
      :shipment="shipment"
      :index="index"
    )

    addresses-and-extra-info(
      :order="order"
      :cart-items="cartItems"
      :cart-service="cartService"
    )

    option-buttons(
      :order="order"
      :cart-service="cartService"
      :jwt-token="jwtToken"
      :show-detail-link="false"
    )
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import NumberAndPlacedDate from './number-and-placed-date.vue'
import StateList from './state-list.vue'
import ShipmentDetail from '../order_shipment/detail.vue'
import AddressesAndExtraInfo from './addresses-and-extra-info.vue'
import OptionButtons from './option-buttons.vue'

export default {
  components: {
    NumberAndPlacedDate,
    StateList,
    ShipmentDetail,
    AddressesAndExtraInfo,
    OptionButtons
  },

  mixins: [checkoutFlowMixin],

  props: {
    currentStep: {
      required: false
    },

    giftItems: {
      type: Array,
      required: true
    },

    jwtToken: {
      type: String,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isOrderLoaded() {
      return typeof this.order.id === 'string'
    },

    shipments() {
      return this.$store.getters['orderShipments/all']
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
