<template lang="pug">
.vc-order-addresses-and-extra-info
  .info-column.left
    .info-section
      .section-label
        span.text {{ attributeLocaleText('order', 'shipping_address') }}
      address-info-unit(
        :order="order"
        address-type="shipping"
      )
    .info-section
      .section-label
        span.text {{ attributeLocaleText('order', 'billing_address') }}
      address-info-unit(
        :order="order"
        address-type="billing"
      )
    .info-section
      .section-label
        span.text {{ modelNameLocaleText('shipping_method') }}
      .vc-order-shipping-method-selector.is-confirmed
        .selectable-method-unit.is-selected
          .select-button
          .selectable-method
            .icon
              i.fa.fa-truck
            span {{ orderShipment.gateway_info.method_name }}

  .info-column.right
    .info-section
      .section-label
        span.text {{ modelNameLocaleText('payment_method') }}
      .vc-order-payment-method-selector.is-confirmed
        .selectable-method-unit.is-selected
          .select-button
          .selectable-method
            .icon
              i.fa(
                :class="methodIconClass(orderPayment.gateway_info.payment_type)"
              )
            span {{ orderPayment.gateway_info.method_name }}

    .info-section(v-if="orderInvoice")
      .section-label
        span.text {{ modelNameLocaleText('order/invoice') }}
      .section-content
        span.text {{ orderInvoice.invoice_number }}

    .info-section(v-if="creditNotes.length > 0")
      .section-label
        span.text {{ modelNameLocaleText('order/credit_note') }}
      .section-content(v-for="creditNote in creditNotes")
        .info-unit
          .label {{ attributeLocaleText('order/credit_note', 'number') }}
          .info-content {{ creditNote.number }}
        .info-unit
          .label {{ attributeLocaleText('order/credit_note', 'remain_amount') }}
          .info-content {{ toMoney(creditNote.remain_amount).format() }}

    .info-section(v-if="order.notes")
      .section-label
        span.text {{ attributeLocaleText('order', 'notes') }}
      .section-content
        .order-notes {{ order.notes }}

    .info-section
      .section-label
        span.text {{ copyLocaleText('checkout_detail') }}
      price-detail(:order="order")
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import AddressInfoUnit from './address-info-unit.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'

export default {
  components: {
    AddressInfoUnit,
    ShippingMethodSelector
  },

  mixins: [checkoutFlowMixin],

  props: {
    currentStep: {
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    orderShipment() {
      return this.$store.getters['orderShipments/find'](
        this.order.shipments[0].id
      )
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    methodIconClass(paymentType) {
      return {
        'fa-credit-card': paymentType === 'credit_card',
        'atm-icon': paymentType === 'vacc'
      }
    }
  }
}
</script>
