import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'brands',
  attributes: [
    'id',
    'name',
    'introduce',
    'description',
    'products_count',
    'logo',
    'created_at',
    'updated_at',
    'slug',
    'meta_title',
    'meta_description',
    'meta_keywords'
  ],
  editableAttributes: [
    'name',
    'introduce',
    'description',
    'uploaded_attachment_ids',
    'slug',
    'meta_title',
    'meta_description',
    'meta_keywords'
  ]
}

export default class Brand extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static randomBrands() {
    return axios.get(`${new this().apiBasePath()}/random`)
  }

  updateLogo(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/logo`, formData)
  }

  fetchDistributionBases(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/distribution_bases?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchBanners(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/banners?${FetchingDataOptionsService.call(options)}`
    )
  }

  static uploadAttachments(formData) {
    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/editor_attachments`,
      formData
    )
  }

  bulkAssignProducts(productIds) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_assign`, {
      product_ids: productIds
    })
  }

  bulkMoveProducts(data) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_move`, {
      target_brand_id: data.targetBrand.id,
      product_ids: data.productIds
    })
  }

  bulkRemoveProducts(productIds) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_remove`, {
      product_ids: productIds
    })
  }

  removeProduct(product) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/products/${product.id}/remove`
    )
  }

  // extra methods or helpers here...

  hasLogo() {
    return this.logo && this.logo.url !== null
  }
}
