export default {
  // components: {},
  // props: {},
  // data() {
  //   return {}
  // },

  computed: {
    usages() {
      return this.$store.getters['categorySystems/allCategories']({
        depth: 1
      })
    },

    usage() {
      return this.usages.find((usage) => usage.default_usage === 'blog')
    },

    categories() {
      if (this.usage)
        return this.$store.getters['categorySystems/allCategories']({
          depth: 2
        }).filter((category) => String(category.parent_id) === this.usage.id)
    }
  },

  mounted() {
    this._ensureCategoriesLoaded()
  },

  methods: {
    _ensureCategoriesLoaded() {
      if (this.categories) return this.$emit('categories-loaded')

      this.$store
        .dispatch('categorySystems/fetchCategoriesByType', {
          type: 'promotional_article',
          sort: 'created_at'
        })
        .then(() => {
          this.$emit('categories-loaded')
        })
    },

    subCategories(parentCategory) {
      return this.$store.getters['categorySystems/allCategories']({
        depth: 3
      }).filter((category) => String(category.parent_id) === parentCategory.id)
    }
  }
}
