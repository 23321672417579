<template lang="pug">
.vc-sales-event-random-events(v-if="salesEvents.length > 0")
  .section-header
    .odd-container.has-padding
      h2.section-title
        .icon
          img(:src="icon")
        .en on sale
        .zh {{ copyLocaleText('flash_sale') }}
      //- .tools-wrapper
  .section-body
    .odd-container.has-padding
      b-loading(
        :active.sync="isLoading"
        :is-full-page="false"
      )

      sales-event-card(
        v-for="(salesEvent, index) in salesEvents"
        :key="index"
        :sales-event="salesEvent"
      )

      .options-wrapper
        a.button.is-odd(href="/sales-events")
          span
            | {{ actionLocaleText('more', { model: modelNameLocaleText('sales_event') }) }}
          .icon
            i.fa.fa-chevron-right
</template>

<script>
import priceTag from '../../../images/price-tag.svg'
import SalesEventCard from './card.vue'

export default {
  components: {
    SalesEventCard
  },
  // mixins: [],
  props: {
    eventsCount: {
      type: Number,
      required: false,
      default: () => {
        return 3
      }
    }
  },

  data() {
    return {
      randomSalesEventsIds: null,
      isLoading: true
    }
  },

  computed: {
    salesEvents() {
      if (this.randomSalesEventsIds) {
        return this.$store.getters['salesEvents/all'].filter((salesEvent) =>
          this.randomSalesEventsIds.includes(salesEvent.id)
        )
      } else {
        return []
      }
    },

    icon() {
      return priceTag
    }
  },

  created() {
    this.$store
      .dispatch('salesEvents/randomEvents', {
        pageNumber: 1,
        pageSize: this.eventsCount
      })
      .then((response) => {
        this.randomSalesEventsIds = response.data.data.map(
          (salesEvent) => salesEvent.id
        )
        this.isLoading = false
      })
  }
  // mounted() {},
  // methods: {}
}
</script>
