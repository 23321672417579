<template lang="pug">
form.vc-order-payment-button(
  :action="gatewayApiBase"
  method="post"
  ref="payment-form"
)
  button.button.is-odd(
    type="submit"
    @click.prevent="makePayment"
    :class="{ 'is-loading': isProcessing }"
  ) {{ actionLocaleText('finalize_and_make_payment') }}
</template>

<script>
import paymentButtonMixin from '../mixins/payment_button_mixin.js'

export default {
  // components: {},
  mixins: [paymentButtonMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    isLoading() {
      return (
        this.$store.getters['users/isLoading'] ||
        this.$store.getters['orders/isLoading']
      )
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
