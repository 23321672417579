<template lang="pug">
.vc-product-document-list
  document-unit(
    v-for="document in supportDocuments"
    :key="document.id"
    :document="document"
  )
</template>

<script>
import DocumentUnit from '../product_support_document/unit.vue'

export default {
  components: {
    DocumentUnit
  },
  // mixins: [],
  props: {
    supportDocuments: {
      type: Array,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {}
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
