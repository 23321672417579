import salesEventMixin from '../../components/mixins/sales_event_mixin.js'
import SalesEventProductList from '../../components/sales_event/product-list.vue'

export default {
  name: 'sales-event-show-page-container',

  components: {
    SalesEventProductList
  },

  mixins: [salesEventMixin],

  // props: {},

  data() {
    return {
      salesEventId: null,
      isLoaded: false
    }
  },

  computed: {
    salesEvent() {
      return this.$store.getters['salesEvents/find'](this.salesEventId)
    },

    isLoading() {
      return !this.isLoaded
    }
  },

  // created() {},

  mounted() {
    this.salesEventId = this.$el.dataset.salesEvent

    this.$store
      .dispatch('salesEvents/find', this.salesEventId)
      .then((_) => {
        this.isLoaded = true
      })
      .catch((_) => {
        this.isLoaded = true
      })
  }

  // methods: {}
}
