<template lang="pug">
.vc-order-address-info-unit
  .info-unit(v-if="addressType === 'shipping'")
    .label {{ attributeLocaleText('order', 'email') }}
    .info-content {{ order.email }}
  .info-unit
    .label {{ attributeLocaleText('order/address', 'recipient') }}
    .info-content {{ address.recipient }}
  .info-unit
    .label {{ attributeLocaleText('order/address', 'phone') }}
    .info-content {{ address.phone }}
  template(v-if="address.hasCompanyInfo()")
    .info-unit
      .label {{ attributeLocaleText('order/address', 'company') }}
      .info-content {{ address.company }}
    .info-unit
      .label {{ attributeLocaleText('order/address', 'ein') }}
      .info-content {{ address.ein }}
  .info-unit
    .label {{ attributeLocaleText('order/address', 'address') }}
    .info-content {{ address.fullAddress() }}
</template>

<script>
import UserCommonAddress from '../../../../shared/resource_models/user_common_address.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    },

    addressType: {
      type: String,
      required: true,
      validator: (value) => {
        return ['shipping', 'billing'].includes(value)
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    address() {
      return new UserCommonAddress(this.order[`${this.addressType}_address`])
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
