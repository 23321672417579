<template lang="pug">
.vc-order-promo-code-checker(v-if="promoCodeUseable")
  b-field.code-field(
    :label="attributeLocaleText('order', 'coupon_code')"
    :type="validateResultType"
    :message="errors.get('coupon_code')"
  )
    b-field(
      custom-class="code-field"
      :type="validateResultType"
    )
      b-input(
        type="text"
        :placeholder="messageLocaleText('enter_promo_code')"
        v-model="promoCode"
        @input="resetStatus"
        expanded
      )
      .control
        a.button.is-small.is-info(
          @click="checkPromoCode"
          :disabled="!isValidPromoCodeLength"
        ) {{ actionLocaleText('use_promo_code') }}

  .applied-coupons(v-if="appliedCoupons.length > 0")
    coupon-unit(
      v-for="(coupon, index) in appliedCoupons"
      :key="index"
      :coupon="coupon"
    )
</template>

<script>
import CouponUnit from './coupon-unit.vue'

export default {
  components: {
    CouponUnit
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      promoCode: '',
      isPromoCodeChecked: false,
      isPromoCodeValid: false
    }
  },

  computed: {
    errors() {
      return this.$store.getters['users/errors']
    },

    promoCodeValidatingClass() {
      return {
        'is-loading': this.$store.getters['users/isLoading']
      }
    },

    isValidPromoCodeLength() {
      return this.promoCode.length >= 4
    },

    validateResultType() {
      return {
        'is-danger': this.isPromoCodeChecked && !this.isPromoCodeValid,
        'is-success': this.isPromoCodeChecked && this.isPromoCodeValid
      }
    },

    // TODO: waiting implement
    promoCodeUseable() {
      return true
    },

    appliedCoupons() {
      return this.order.coupons
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    checkPromoCode() {
      if (!this.isValidPromoCodeLength) return

      this.$store
        .dispatch('users/applyCoupon', this.promoCode)
        .then((_) => {
          this.isPromoCodeValid = true
          this.isPromoCodeChecked = true
        })
        .catch((_) => {
          this.isPromoCodeValid = false
          this.isPromoCodeChecked = true
        })
    },

    resetStatus() {
      this.errors.clear('coupon_code')
      this.isPromoCodeChecked = false
      this.isPromoCodeValid = false
    }
  }
}
</script>
