import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'site_config',
  attributes: [
    'meta_tags',
    'brand_short_video',
    'trackers',
    'notification_system',
    'store_preferences',
    'site_custom_display_config',
    'site_feature_configs'
  ],
  editableAttributes: [
    'meta_tags',
    'brand_short_video',
    'trackers',
    'notification_system',
    'store_preferences',
    'site_custom_display_config'
  ]
}

export default class SiteConfig extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchCustomDisplayConfig() {
    return axios.get(`${new this().apiBasePath()}/custom_display`)
  }

  static fetchWebsitePreferences() {
    return axios.get(`${new this().apiBasePath()}/website_preferences`)
  }

  static fetchStorePreferences() {
    return axios.get(`${new this().apiBasePath()}/store_preferences`)
  }

  static updateDataPosition(data) {
    return axios.put(`${new this().apiBasePath()}/data_positioning`, {
      data: {
        type: OPTIONS.resourceType,
        attributes: data
      }
    })
  }

  static updateHolidayDatabase() {
    return axios.post(`${new this().apiBasePath()}/update_holiday_database`)
  }

  static getSiteFeatureConfigs() {
    return axios.get(`${new this().apiBasePath()}/site_feature_configs`)
  }

  updateWebsitePreferences() {
    const scopes = ['meta_tags', 'trackers']
    let result = {}

    scopes.forEach((scope) => {
      Object.keys(this[scope]).forEach((key) => {
        result[`${scope}.${key}`] = this[scope][key]
      })
    })

    return axios.put(
      `${this.apiBasePath()}/website_preferences`,
      this.partialConfigRequestBody(result)
    )
  }

  updateBrandShortVideo() {
    return axios.put(
      `${this.apiBasePath()}/brand_short_video`,
      this.partialConfigRequestBody(this.brand_short_video)
    )
  }

  updateStorePreferences() {
    const scopes = ['notification_system', 'store_preferences']
    let result = {}

    scopes.forEach((scope) => {
      Object.keys(this[scope]).forEach((key) => {
        result[`${scope}.${key}`] = this[scope][key]
      })
    })

    return axios.put(
      `${this.apiBasePath()}/store_preferences`,
      this.partialConfigRequestBody(result)
    )
  }

  static updateCustomDisplayConfig(config) {
    const instance = new this()

    return axios.put(
      `${instance.apiBasePath()}/custom_display`,
      instance.partialConfigRequestBody(config)
    )
  }

  updateRmaConfig() {
    return axios.put(
      `${this.apiBasePath()}/rma`,
      this.partialConfigRequestBody(this.rma)
    )
  }

  // helpers

  /**
   * 僅更新特定 configs 時使用的 request body
   *
   * @param {Object} configs
   * @returns {Object} JSON:API 格式的 request body
   * @memberof SiteConfig
   */
  partialConfigRequestBody(configs) {
    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: configs
      }
    }
  }
}
