<template lang="pug">
.vc-order-final-confirmation.odd-container.has-padding
  cart-item-list(
    :order="order"
    :cart-items="cartItems"
    :gift-items="giftItems"
    :cart-service="cartService"
  )

  addresses-and-extra-info(
    :order="order"
    :cart-items="cartItems"
    :cart-service="cartService"
  )

  .cart-options
    back-to-store-button
    payment-button(
      :order="order"
      :cart-service="cartService"
      @back-to-first-step="backToFirstStepHandler"
    )
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'
import AddressesAndExtraInfo from './addresses-and-extra-info.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'
import BackToStoreButton from './back-to-store-button.vue'
import PaymentButton from './payment-button.vue'

export default {
  components: {
    CartItemList,
    AddressesAndExtraInfo,
    ShippingMethodSelector,
    BackToStoreButton,
    PaymentButton
  },

  mixins: [checkoutFlowMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    giftItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']('gift')
    }
  },
  // created() {},
  mounted() {
    this._tryFetchPaymentMethods()
    this._trackCheckoutProgress()
  },

  methods: {
    backToFirstStepHandler() {
      this._scrollToTop()
      this.$emit('update:currentStep', 1)
    }
  }
}
</script>
