import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'
import OrderDetailContent from '../../components/order/detail-content.vue'

export default {
  name: 'search-order-page-container',

  components: {
    ShoppingCartService,
    OrderDetailContent
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      cartService: undefined,
      email: undefined,
      number: undefined,
      orderToken: undefined,
      jwtToken: undefined
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    isOrderLoaded() {
      return typeof this.orderToken === 'string'
    },

    order() {
      return this.$store.getters['orders/find'](this.orderToken)
    },

    cartItems() {
      if (this.order.items) {
        return this.order.items.map((item) =>
          this.$store.getters['orderItems/find'](item.id)
        )
      } else {
        return []
      }
    },

    giftItems() {
      if (this.order.gift_items) {
        return this.order.gift_items.map((item) =>
          this.$store.getters['orderItems/find'](item.id)
        )
      } else {
        return []
      }
    }
  },

  created() {
    this.cartService = new ShoppingCartService(this.$vlf, this.$store)
  },

  // mounted() {},

  methods: {
    searchOrder() {
      this.$store
        .dispatch('users/searchOrder', {
          email: this.email,
          orderNumber: this.number
        })
        .then((response) => {
          this.orderToken = response.data.data.id
          this.jwtToken = response.data.meta.token
        })
    }
  }
}
