import PromoterEventProductList from '../../components/promoter_event/product-list.vue'
import PromoterEventStorageService from '../../../../shared/services/promoter_event_storage_service.js'
import DefaultPromoterBgImage from '../../../../application/images/promoter-default-bg-image.jpg'
import MemberCartButton from '../../components/common/member-cart-button.vue'

export default {
  name: 'promoter-event-show-page-container',

  components: {
    PromoterEventProductList,
    MemberCartButton
  },

  mixins: [],

  // props: {},

  data() {
    return {
      promoterEventId: null,
      promoterShareId: null,
      isLoaded: false,
      promoterEventStorageService: null
    }
  },

  computed: {
    promoterEvent() {
      return this.$store.getters['promoterEvents/find'](this.promoterEventId)
    },

    promoterShare() {
      return this.$store.getters['promoterShares/find'](this.promoterShareId)
    },

    promoter() {
      return this.$store.getters['promoters/find'](
        this.promoterShare.promoter_id
      )
    },

    isLoading() {
      return !this.isLoaded
    },

    promoterBgImage() {
      return this.promoter.bg_image.url || DefaultPromoterBgImage
    },

    checkOrderPath() {
      return `/me/${this.promoterShare.hashid}/check_order`
    }
  },

  // created() {},

  async mounted() {
    this.promoterEventId = this.$el.dataset.promoterEvent
    this.promoterShareId = this.$el.dataset.promoterShare
    this.promoterEventStorageService = new PromoterEventStorageService(
      this.$vlf
    )

    try {
      await this.$store.dispatch('promoterShares/find', this.promoterShareId)
      await this.promoterEventStorageService.savePromoterShareToken(
        this.promoterShare.token
      )
      await this.$store.dispatch(
        'promoters/find',
        this.promoterShare.promoter_id
      )
      this.isLoaded = true
      await this.tryRestoreScrollPosition()
    } catch (error) {
      this.isLoaded = true
    }
  }

  // methods: {}
}
