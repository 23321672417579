import * as types from './mutation-types'
import PromoterShare from '../../resource_models/promoter_share'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterShare.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_SHARES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterShare.find(id)
      .then((response) => {
        commit(types.GET_PROMOTER_SHARE_SUCCESS, response)
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_SHARES_SUCCESS, response)

    resolve(response)
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')
  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_SHARE_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggle = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggle')

  return new Promise((resolve, reject) => {
    model
      .toggle()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggle,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const revokeToken = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'revokeToken')

  return new Promise((resolve, reject) => {
    model
      .revokeToken()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: revokeToken,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateInfo')

  return new Promise((resolve, reject) => {
    model
      .updateInfo()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateSeo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateSeo')

  return new Promise((resolve, reject) => {
    model
      .updateSeo()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSeo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateTopProductIds = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateTopProductIds')

  return new Promise((resolve, reject) => {
    model
      .updateTopProductIds()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateTopProductIds,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDashboardData = (
  { dispatch, commit },
  { dataType, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchDashboardData')
  return new Promise((resolve, reject) => {
    PromoterShare.fetchDashboardData(dataType, options)
      .then((response) => {
        commit(types.GET_DASHBOARD_DATA_SUCCESS, {
          response,
          dataType
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDashboardData,
            ref: {
              dispatch,
              commit
            },
            params: {
              dataType,
              options
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const fetchOrders = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchOrders')

  return new Promise((resolve, reject) => {
    model
      .fetchOrders(options)
      .then((response) => {
        dispatch(
          'orders/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchOrders')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrders,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const checkOrder = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'checkOrder')

  return new Promise((resolve, reject) => {
    model
      .checkOrder(options)
      .then((response) => {
        dispatch(
          'orders/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchOrders')

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: checkOrder,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDescendants = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchDescendants')

  return new Promise((resolve, reject) => {
    model
      .fetchDescendants(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchDescendants')
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.FETCH_DESCENDANT_PROMOTER_SHARES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDescendants,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchProducts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then((response) => {
        if (options.replace) {
          dispatch(
            'products/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch('products/receiveResourcesFromRelationships', response, {
            root: true
          })
        }

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchTopProducts = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchTopProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchTopProducts(options)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchTopProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchTopProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

/**
 * toggle Promoter::Share `is_suspended` 的狀態
 *
 * @param {number} id 指定的 resource ID
 * @returns {promise} response or errors
 */
export const suspend = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'suspend')
  return new Promise((resolve, reject) => {
    model
      .suspend(options)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: suspend,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadAttachments = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'uploadAttachments')

  return new Promise((resolve, reject) => {
    PromoterShare.uploadAttachments(formData)
      .then((response) => {
        commit(types.UPLOAD_ATTACHMENTS_SUCCESS)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadAttachments,
            ref: {
              dispatch,
              commit
            },
            params: formData
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchShareProductShips = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchShareProductShips')

  return new Promise((resolve, reject) => {
    model
      .fetchShareProductShips(options)
      .then((response) => {
        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'promoterShareVariantShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchShareProductShips')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShareProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
