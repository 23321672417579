<template lang="pug">
.vc-blog-hot-blogs
  .blog-section-header.-border-bottom
    h2.section-title.-justify-left {{ copyLocaleText('hot_blogs') }}
    .tools-wrapper
      //- .icon
      //-   i.fa.fa-chevron-right
  .blog-section-body
    b-loading(
      :active.sync="isLoading"
      :is-full-page="false"
    )

    .blog-list
      blog-card(
        v-for="(blog, index) in blogs"
        :blog="blog"
        :index="index"
        :key="blog.id"
      )

    odd-pagination(
      :total="totalCount"
      :current.sync="currentPage"
      :per-page="pageSize"
      size="is-small"
      order="is-centered"
      :rounded="true"
      @change="onPageChange"
    )
</template>

<script>
import BlogCard from './card.vue'
import OddPagination from '../../../../application/js/components/common/odd-pagination.vue'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'

export default {
  components: {
    BlogCard,
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      test: true,
      blogIds: null,
      resourceType: 'promotionalArticles',
      currentPage: 1,
      pageSize: 6,
      sortOrder: 'desc',
      sortField: 'view_count'
    }
  },

  computed: {
    blogs() {
      if (this.blogIds)
        return this.$store.getters['promotionalArticles/all'].filter((blog) =>
          this.blogIds.includes(blog.id)
        )
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    }
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store
        .dispatch(
          'promotionalArticles/latestArticles',
          Object.assign(options, {
            usage: 'blog',
            noReplace: true
          })
        )
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
        })
    },

    // no need for update url
    updateQueryString(options) {}
  }
}
</script>
