<template lang="pug">
.vc-order-state-list
  .state-unit.order-state(:class="order.orderStateType()")
    span.title {{ attributeLocaleText('order', 'order_state') }}
    span.content {{ aasmStateLocaleText('order', 'order_state', order.order_state) }}
  .state-unit.payment-state(:class="order.paymentStateType()")
    span.title {{ attributeLocaleText('order', 'payment_state') }}
    span.content {{ aasmStateLocaleText('order', 'payment_state', order.payment_state) }}
  .state-unit.shipment-state(:class="order.shipmentStateType()")
    span.title {{ attributeLocaleText('order', 'shipment_state') }}
    span.content {{ aasmStateLocaleText('order', 'shipment_state', order.shipment_state) }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
