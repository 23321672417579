<template lang="pug">
.vc-promotional-article-card
  a.inner(:href="targetPath")
    //- .cover-wrapper
    img.cover(:src="coverSrc")
    .content
      .article-title {{ promotionalArticle.title }}
</template>

<script>
const MOBILE_IMAGE_BREAK_POINT = 429

export default {
  // components: {},
  // mixins: [],
  props: {
    promotionalArticle: {
      type: Object,
      required: true
    },

    useBlogLink: {
      type: Boolean,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    coverSrc() {
      return this.isMobile
        ? this.promotionalArticle.mobile_cover.url ||
            this.promotionalArticle.cover.url
        : this.promotionalArticle.cover.url
    },

    targetPath() {
      if (this.useBlogLink)
        return `/babynews/${this.promotionalArticle.id}/${this.promotionalArticle.slug}`

      return `/promotional-articles/${this.promotionalArticle.id}`
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
