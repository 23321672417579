<template lang="pug">
.vc-brand-list
  .brands-wrapper
    summary-unit(
      v-for="brand in brands"
      :brand="brand"
      :key="brand.id"
    )

  odd-pagination(
    :total="totalCount"
    :current.sync="currentPage"
    :per-page="pageSize"
    size="is-small"
    order="is-centered"
    :rounded="true"
    @change="onPageChange"
  )
</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import SummaryUnit from './summary-unit.vue'
import oddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    SummaryUnit,
    oddPagination
  },
  mixins: [backendPaginateFilterSortAndSearchableMixin],
  // props: {},
  data() {
    return {
      resourceType: 'brands',

      pageSize: 10, //                                  每頁數量
      sortOrder: 'asc', //                             排序方向
      sortField: 'name', //                       排序欄位
      availableFilters: [] //    可用的 filters 列表
    }
  },

  computed: {
    brands() {
      return this.$store.getters['brands/all']
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    }
  },

  created() {
    this.fetchingInitialData()
  }
  // mounted() {},
  // methods: {}
}
</script>
