import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'
import Vue from 'vue/dist/vue.esm'

const helpers = new MutationHelpers('promoter_shares')

export default {
  [types.FETCH_PROMOTER_SHARES_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.FETCH_DESCENDANT_PROMOTER_SHARES_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_PROMOTER_SHARES_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_PROMOTER_SHARE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_DASHBOARD_DATA_SUCCESS](state, { response, dataType }) {
    const keys = Object.keys(response.data)
    state.isCallingAPI = false

    keys.forEach((key) => {
      Vue.set(state.dashboard, key, response.data[key])
    })
  },

  [types.ADD_PROMOTER_SHARE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_PROMOTER_SHARE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.UPLOAD_ATTACHMENTS_SUCCESS](state) {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
