<template lang="pug">
.vc-sales-event-list
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .list-wrapper
    sales-event-card(
      v-for="event in salesEvents"
      :key="event.id"
      :sales-event="event"
    )

  odd-pagination(
    :total="totalCount"
    :current.sync="currentPage"
    :per-page="pageSize"
    size="is-small"
    order="is-centered"
    :rounded="true"
    @change="onPageChange"
  )
</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import SalesEventCard from './card.vue'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    OddPagination,
    SalesEventCard
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  props: {
    category: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      resourceType: 'salesEvents',
      pageSize: 4,
      sortOrder: 'asc',
      sortField: 'position',
      availableFilters: ['not_expired']
    }
  },

  computed: {
    additionalOptions() {
      if (!this.category) return {}

      return {
        category_id: this.category ? this.category.id : null
      }
    },

    salesEvents() {
      return this.$store.getters['salesEvents/all']
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    }
  },

  watch: {
    category(category) {
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
      this._scrollToListTop()
    }
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch(
        `${this.resourceType}/all`,
        Object.assign(options, this.additionalOptions)
      )
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.category_id) result += `&category_id=${options.category_id}`

      return result
    }
  }
}
</script>
