<template lang="pug">
.vc-product-distribution-base-list
  p.help-message(
    v-if="!isAtDistributionBasePage"
    v-html="copyLocaleText('go_to_distribution_base_page_for_more_info_and_check_with_dealer_before_visit_store_html')"
  )
  template(v-if="hasContents")
    distribution-base-unit(
      v-for="distributionBase in distributionBases"
      :key="distributionBase.id"
      :distribution-base="distributionBase"
    )
  template(v-else)
    .empty-state
      .icon
        i.fa.fa-smile-o
      span {{ messageLocaleText('there_is_no_x_for_now', { x: modelNameLocaleText('distribution_base') }) }}
</template>

<script>
import DistributionBaseUnit from '../distribution_base/unit.vue'

export default {
  components: {
    DistributionBaseUnit
  },
  // mixins: [],
  props: {
    distributionBases: {
      type: Array,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    hasContents() {
      return this.distributionBases.length > 0
    },

    isAtDistributionBasePage() {
      return ['/distribution_bases', '/distribution-bases'].includes(
        window.location.pathname
      )
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
