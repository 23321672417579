<template lang="pug">
.vc-brand-card
  a.logo-wrapper(:href="brandPath")
    img.logo(v-lazy="logoUrl")
  .brand-info
    .brand-name
      h4.name {{ brand.name }}
    .product-count
      span.counter {{ brand.products_count }}
      span.text {{ messageLocaleText('count_of', { model: modelNameLocaleText('product') }) }}
</template>

<script>
import brandBasicMixins from '../mixins/brand_basic_mixin.js'

export default {
  // components: {},

  mixins: [brandBasicMixins]

  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
