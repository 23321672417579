<template lang="pug">
.vc-register-banner
  .slideshow-wrapper
    swiper(:options="swiperOption")
      swiper-slide(
        v-for="banner in banners"
        :key="banner.id"
      )
        img.image(:src="banner.image.url")
      .swiper-pagination(slot="pagination")
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },
  // mixins: [],
  // props: {},
  data() {
    return {
      swiperOption: {
        effect: 'fade',
        grabCursor: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: true
        },
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },

      useType: 'sign_up'
    }
  },

  computed: {
    banners() {
      return this.$store.getters['banners/allByType'](this.useType)
    }
  },

  created() {
    this.$store.dispatch('banners/all', {
      filter: this.useType
    })
  }
  // mounted() {},
  // methods: {}
}
</script>
