<template lang="pug">
.vc-common-blog-search-button.function-button
  b-dropdown(
    :triggers="['click', 'hover']"
    position="is-bottom-left"
    hoverable
  )
    a.link(
      slot="trigger"
      href="#"
      @click.prevent=""
    )
      .icon.search
        .fa.fa-search.fa-lg
    b-dropdown-item(custom)
      //- b-field(:label="actionLocaleText('search')")
      b-field
        input.input(
          type="text"
          :placeholder="actionLocaleText('search_by', { attribute_name: `${attributeLocaleText('promotional_article', 'title')}、${attributeLocaleText('promotional_article', 'keywords')}` })"
          v-model="searchText"
          @keydown.enter="search"
          @compositionend="composing = false"
          @compositionstart="composing = true"
        )
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      modalActived: false,
      searchText: '',
      composing: false
    }
  },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    openSearchModal() {
      this.modalActived = true
    },

    search() {
      if (this.composing) return

      Turbolinks.visit(
        encodeURI(
          `/categories/babynews?q[title_or_meta_keywords_or_meta_description_cont]=${this.searchText}`
        )
      )
    }
  }
}
</script>
