import productImpressionMixin from '../../components/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ProductCard from '../../components/product/card.vue'
import OddPagination from '../../components/common/odd-pagination.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  name: 'collection-list-container',

  components: {
    ProductCard,
    OddPagination
  },

  mixins: [productImpressionMixin, backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'products',
      currentPage: 1,
      pageSize: 12,
      sortOrder: 'desc',
      searchOptions: {
        id_in: []
      },
      sortField: 'created_at'
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    products() {
      return this.$store.getters['products/all']
    },

    currentCollections() {
      return this.$store.getters['userCollections/currentCollections']
    },

    listLocation() {
      return `User Collection List`
    }
  },

  watch: {
    currentCollections(value) {
      this.searchOptions.id_in = value

      if (value.length > 0) {
        this.fetchingInitialData()
      } else {
        this.$store.dispatch('products/clearStore')
      }
    }
  },

  // created() {},

  // mounted() {},

  methods: {
    // do not update querystring
    updateQueryString(options) {},

    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch(`${this.resourceType}/all`, options).then(() => {
        this.$store.dispatch('ga4Operation', [
          generateGA4Events('view_item_list', {
            items: this.products,
            item_list_name: this.listLocation
          })
        ])
      })
    }
  }
}
