<template lang="pug">
.vc-order-payment-method-selector(:class="{ 'is-confirmed': isConfirmed }")
  .selectable-method-unit(
    v-for="method in paymentMethods"
    v-if="isSelected(method) || !isConfirmed"
    :key="method.id"
    @click="selectPaymentMethod(method)"
    :class="{ 'is-selected': isSelected(method) }"
  )
    .select-button
    .selectable-method
      .icon
        i.fa(:class="methodIconClass(method)")
      span {{ method.name }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    selectedPaymentMethodId: {
      type: [String, Number],
      required: false
    },

    paymentMethods: {
      type: Array,
      required: true
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    selectedPaymentMethod() {
      return this.paymentMethods.find(
        (method) => method.id === this.selectedPaymentMethodId
      )
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    selectPaymentMethod(method) {
      if (this.isConfirmed) return

      this.$emit('update:selectedPaymentMethodId', method.id)
      this.$emit('form-edited')
      this._sendGaCheckoutOptionEvent(method)
    },

    isSelected(method) {
      return method.id === this.selectedPaymentMethodId
    },

    methodIconClass(method) {
      return {
        'fa-credit-card': [
          'credit_card',
          'credit_card_inst_3',
          'credit_card_inst_6',
          'credit_card_inst_12',
          'credit_card_inst_18',
          'credit_card_inst_24',
          'credit_card_inst_30'
        ].includes(method.payment_type),
        'atm-icon': method.payment_type === 'vacc'
      }
    },

    _sendGaCheckoutOptionEvent(method) {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:setAction',
          'checkout_option',
          {
            step: 2,
            option: method.name
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'Ecommerce',
            eventAction: 'Select Payment Method'
          }
        ]
      ])
    }
  }
}
</script>
