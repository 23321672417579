import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'
import users from '../../../shared/store_modules/users'
import userCollections from '../../../shared/store_modules/user_collections'
import userCommonAddresses from '../../../shared/store_modules/user_common_addresses'
import brands from '../../../shared/store_modules/brands'
import distributionBases from '../../../shared/store_modules/distribution_bases'
import salesEvents from '../../../shared/store_modules/sales_events'
import brandBanners from '../../../shared/store_modules/brand_banners'
import categorySystems from '../../../shared/store_modules/category_systems'
import contents from '../../../shared/store_modules/contents'
import productCategories from '../../../shared/store_modules/product_categories'
import products from '../../../shared/store_modules/products'
import productOptionTypes from '../../../shared/store_modules/product_option_types'
import productOptionValues from '../../../shared/store_modules/product_option_values'
import productVariants from '../../../shared/store_modules/product_variants'
import productImages from '../../../shared/store_modules/product_images'
import productSupportDocuments from '../../../shared/store_modules/product_support_documents'
import shippingMethods from '../../../shared/store_modules/shipping_methods'
import paymentMethods from '../../../shared/store_modules/payment_methods'
import banners from '../../../shared/store_modules/banners'
import orders from '../../../shared/store_modules/orders'
import orderItems from '../../../shared/store_modules/order_items'
import orderShipments from '../../../shared/store_modules/order_shipments'
import orderPayments from '../../../shared/store_modules/order_payments'
import returnAuthorizations from '../../../shared/store_modules/return_authorizations'
import comments from '../../../shared/store_modules/comments'
import infoLinks from '../../../shared/store_modules/info_links'
import siteConfigs from '../../../shared/store_modules/site_configs'
import CurrentUserAssignService from '../../../shared/services/current_user_assign_service'
import promotionalArticles from '../../../shared/store_modules/promotional_articles'
import promoters from '../../../shared/store_modules/promoters'
import promoterEvents from '../../../shared/store_modules/promoter_events'
import promoterShares from '../../../shared/store_modules/promoter_shares'

Vue.use(Vuex)

const state = {
  currentUser: CurrentUserAssignService.call('user'),
  flashMessages: [],
  queryString: {},
  retryCounter: 0,
  device: {
    isMobile: false,
    deviceType: '',
    windowWidth: 0
  },
  mobileMenuStates: {
    isMobileMenuActive: false,
    activatedCategoryType: '',
    isCategoriesSelectorActive: false,
    selectedTopLevelCategoryId: null,
    isSkippingRootMenuToClose: false
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    users,
    userCollections,
    userCommonAddresses,
    brands,
    brandBanners,
    distributionBases,
    categorySystems,
    contents,
    productCategories,
    products,
    productOptionTypes,
    productOptionValues,
    productVariants,
    productImages,
    productSupportDocuments,
    shippingMethods,
    paymentMethods,
    promotionalArticles,
    salesEvents,
    orders,
    orderItems,
    orderShipments,
    orderPayments,
    returnAuthorizations,
    comments,
    infoLinks,
    siteConfigs,
    banners,
    promoters,
    promoterEvents,
    promoterShares
  }
})
