<template lang="pug">
.vc-return-authorization-form(v-if="returnItems && returnItems.length > 0")
  h3.form-title {{ modelNameLocaleText('return_authorization') }}
  .form-body
    h4.section-title {{ attributeLocaleText('return_authorization', 'selected_items') }}
    .item-unit(
      v-for="returnItem in returnItems"
      :key="returnItem.shipment_item_id"
    )
      .group
        .group.option-and-thumb
          .product-thumb
            img.img(:src="returnItem.image")
        .group.name-and-unit-price
          .product-name
            .name {{ returnItem.name }}
          .unit-price
            label.label {{ attributeLocaleText('order/item', 'price') }}
            .price {{ returnItem.unit_price.toLocaleString() }}
      .group
        .quantity-info
          label.label {{ attributeLocaleText('order/shipment_item', 'quantity') }}
          .quantity {{ returnItem.apply_quantity }}

    h4.section-title {{ attributeLocaleText('return_authorization', 'reason_id') }}
    b-select(
      :placeholder="actionLocaleText('choose_one')"
      v-model="form.reason_id"
      expanded
    )
      option(
        v-for="reason in reasons"
        :key="reason.id"
        :value="reason.id"
      ) {{ reason.name }}

    b-field(
      :label="attributeLocaleText('return_authorization', 'note')"
      label-position="on-border"
    )
      b-input(
        v-model="form.note"
        type="textarea"
      )
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    returnItems() {
      return this.form.items
    },

    reasons() {
      return this.$store.getters['returnAuthorizations/allReasons']
    }
  },

  created() {
    this.$store.dispatch('returnAuthorizations/fetchReasons', {
      sort: '-created_at'
    })
  }
  // mounted() {},
  // methods: {}
}
</script>
