<template lang="pug">
.vc-order-cart-item(
  v-if="isVariantLoaded"
  :class="{ disabled: isConfirmed }"
)
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .part.product-info
    .remove-button(
      role="button"
      @click="removeCartItem"
    )
      .icon
        i.fa.fa-close
    .index-indicator
      .icon {{ index + 1 }}
    .picture-wrapper
      img.picture(:src="variant.displayPicture()")
    .info-wrapper
      .product-name {{ variant.product_name }}

      b-dropdown(
        v-model="form.variant_id"
        :disabled="isConfirmed"
        @change="variantChangedHandler"
      )
        .variants-dropdown(
          v-if="variant.has_more_variants"
          role="button"
          @click="fetchProductInfo"
          slot="trigger"
        )
          .variant-name {{ variant.displayName() }}
          .clickable-indicator
            .icon
              i.fa.fa-chevron-down

        b-loading(
          :active.sync="isProductLoading"
          :is-full-page="false"
        )

        b-dropdown-item(
          v-for="variant in selectableVariants"
          :key="variant.id"
          :disabled="variant.stock < 1"
          :value="variant.id"
        )
          span {{ variant.name }}
          span.message(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}

  .part.quantity-controller
    .unit-price
      span.label {{ attributeLocaleText('order/item', 'price') }}
      span.price {{ toMoney(cartItem.price).format() }}
    quantity-picker(
      :quantity.sync="form.quantity"
      :current-stock="variant.stock"
      @update:quantity="quantityUpdatedHandler"
      :is-confirmed="isConfirmed"
    )
  .part.price-info(:class="useType")
    .sub-total
      span.label {{ attributeLocaleText('order/item', 'sub_total') }}
      span.price {{ subTotal.format() }}
      span.gift-tag(v-if="useType === 'gift'") {{ copyLocaleText('gift') }}
</template>

<script>
import debounce from 'lodash.debounce'
import Form from 'odd-form_object'
import QuantityPicker from '../product/quantity-picker.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    QuantityPicker
  },
  // mixins: [],
  props: {
    index: {
      type: Number,
      required: true
    },

    cartItem: {
      type: Object,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },

  data() {
    return {
      form: new Form(this.cartItem),
      isLoading: false
    }
  },

  computed: {
    variant() {
      return this.$store.getters['productVariants/find'](this.form.variant_id)
    },

    selectableVariants() {
      return this.$store.getters['productVariants/all']
        .filter((variant) => variant.product_id === this.variant.product_id)
        .sort((a, b) => a.id - b.id)
    },

    isProductLoading() {
      return this.$store.getters['products/isLoading']
    },

    isVariantLoaded() {
      return typeof this.variant.id === 'string'
    },

    subTotal() {
      return this.toMoney(this.cartItem.price).multiply(this.form.quantity)
    },

    /**
     * @returns {string}
     */
    useType() {
      return this.cartItem.use_type
    }
  },

  watch: {
    cartItem: {
      handler(newValue) {
        this.form = new Form(newValue)
      }
    }
  },
  // created() {},
  // mounted() {},

  methods: {
    quantityUpdatedHandler: debounce(function (quantity) {
      this.isLoading = true
      this.form.quantity = quantity

      this.cartService.updateCartItem(this.form.sync()).then(() => {
        this.form = new Form(this.cartItem)
        this.isLoading = false
      })
    }, 500),

    removeCartItem() {
      return this.cartService
        .removeCartItem(this.cartItem)
        .then((_) => {
          return this._sendGaRemoveFromCartEvent()
        })
        .then((_) => {
          return this.$store.dispatch('ga4Operation', [
            generateGA4Events('remove_from_cart', {
              item: this.variant,
              quantity: this.cartItem.quantity
            })
          ])
        })
        .then((_) => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('cart_item_removed_successfully')
          ])
        })
    },

    variantChangedHandler(value) {
      if (this.isConfirmed) return

      this.isLoading = true
      this.form.quantity = 1
      this.form.variant_id = value

      this.cartService
        .updateCartItem(this.form.sync())
        .then(() => {
          this.form = new Form(this.cartItem)
          this.isLoading = false
        })
        .catch((errors) => {
          if (
            errors.response &&
            errors.response.status === 422 &&
            errors.response.data.code === 'validation_failure'
          ) {
            this.isLoading = false
            this.form.variant_id = this.cartItem.variant.id
            return this.$store.dispatch('addFlashMessage', [
              'notice',
              Object.values(errors.response.data.validation_errors)[0][0]
            ])
          }
        })
    },

    fetchProductInfo() {
      this.$store.dispatch('products/find', this.variant.product_id)
    },

    _sendGaRemoveFromCartEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addProduct',
            {
              id: this.variant.sku,
              name: this.variant.product_name,
              variant: this.variant.name,
              price: this.toMoney(this.variant.consumer_price).amount,
              quantity: this.cartItem.quantity
            }
          ],
          ['ec:setAction', 'remove'],
          [
            'send',
            {
              hitType: 'event',
              eventCategory: 'Ecommerce',
              eventAction: 'Remove from Cart'
            }
          ]
        ])
        .then((_) => {
          return this.$store.dispatch('dataLayerOperation', {
            event: 'removeFromCart',
            ecommerce: {
              remove: {
                products: [
                  {
                    id: this.variant.sku,
                    name: this.variant.product_name,
                    variant: this.variant.name,
                    price: this.toMoney(this.variant.consumer_price).amount,
                    quantity: this.cartItem.quantity
                  }
                ]
              }
            }
          })
        })
    }
  }
}
</script>
