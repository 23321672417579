<template lang="pug">
.vc-promoter-event-product-list
  b-loading(
    :active.sync="isProductsLoading"
    :is-full-page="false"
  )
  b-tabs.category-tabs(v-model="currentTabIndex")
    b-tab-item(:label="'商城'")
      .products-wrapper
        product-unit(
          v-for="(product, index) in products"
          :key="product.id"
          :product="product"
          :index="index"
          :promoter-event="promoterEvent"
          :promoter-share="promoterShare"
          :list-location="listLocation"
          @ga-impressed="impressedHandler"
        )

      odd-pagination(
        :total="totalCount"
        :current.sync="currentPage"
        :per-page="pageSize"
        size="is-small"
        order="is-centered"
        :rounded="true"
        @change="onPageChange"
      )

    b-tab-item(:label="'介紹'")
      .editor-content(v-html="shareDescription")
</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ProductUnit from './product-unit.vue'
import OddPagination from '../common/odd-pagination.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    ProductUnit,
    OddPagination
  },

  mixins: [productImpressionMixin, backendPaginateFilterSortAndSearchableMixin],

  props: {
    promoterEvent: {
      type: Object,
      required: true
    },

    promoterShare: {
      type: Object,
      requrired: true
    }
  },

  data() {
    return {
      resourceType: 'products',
      currentPage: 1,
      pageSize: 32,
      sortOrder: 'asc',
      sortField: 'promoter_event_product_ships.position',
      currentTabIndex: 0,
      isProductsLoading: false
    }
  },

  computed: {
    tabs() {
      return ['products', 'intro']
    },

    currentTab() {
      return this.tabs[this.currentTabIndex]
    },

    products() {
      return this.$store.getters['products/all']
    },

    listLocation() {
      return `Promoter Event - ${this.promoterEvent.name}`
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    shareDescription() {
      return this.promoterShare.description
    }
  },
  // created() {},
  async mounted() {
    this.initCurrentTab()

    this.fetchingInitialData()
  },

  watch: {
    currentTab: {
      handler() {
        this.updateQueryString(this.currentOptions)
      }
    }
  },

  methods: {
    initCurrentTab() {
      this.currentTabIndex = this.currentQueryString.tab
        ? this.tabs.findIndex((tab) => {
            return tab === this.currentQueryString.tab
          })
        : 0
    },

    async fetchData(options) {
      this.currentPage = options.pageNumber
      this.isProductsLoading = true

      try {
        await this.$store.dispatch(`promoterShares/fetchProducts`, {
          model: this.promoterShare,
          options: Object.assign(options, {
            replace: true,
            share_product_ship_is_available: 1
          })
        })
        await this.$store.dispatch('ga4Operation', [
          generateGA4Events('view_item_list', {
            items: this.products,
            list_name: this.listLocation
          })
        ])
      } catch (e) {
        this.isProductsLoading = false
      }

      this.isProductsLoading = false
    },

    additionalOptionsToQueryString() {
      return `&tab=${this.currentTab}`
    }
  }
}
</script>
