export default {
  methods: {
    initFacebookSDK(options = {}) {
      if (!window.fbAsyncInit) {
        this.initFB(options)
      } else {
        window.fbAsyncInit()
      }
    },

    initFB(options) {
      // assign window fn to process after fb sdk loaded
      window.fbAsyncInit = () => {
        FB.init({
          appId: options.appId,
          xfbml: true,
          version: 'v3.2'
        })

        window.fbInitialized = true
      }

      // make sure fbAsyncInit() executed
      if (window.FB && !window.fbInitialized) window.fbAsyncInit()
    }
  }
}
