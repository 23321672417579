import html2canvas from 'html2canvas'

export default {
  name: 'thanks-for-purchase-show-page-container',

  // components: {},

  // mixins: [],

  // props: {},

  data() {
    return {
      orderNumber: undefined
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    isMobile() {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    }
  },

  // created() {},

  mounted() {
    this.orderNumber = this.$el.dataset.orderNumber
  },

  methods: {
    async downloadImage() {
      const canvas = await html2canvas(this.$refs.capture, { useCORS: true })

      if (this.isMobile) {
        await this.__useShareAPI(canvas)
      } else {
        await this.__normalDownload(canvas)
      }
    },

    /**
     * @param {HTMLCanvasElement} canvas
     */
    __normalDownload(canvas) {
      const tempBtn = document.createElement('a')

      tempBtn.href = canvas.toDataURL()
      tempBtn.download = `${this.orderNumber}.jpg`
      tempBtn.target = '_blank'
      tempBtn.click()
      tempBtn.remove()
    },

    /**
     * @param {HTMLCanvasElement} canvas
     */
    __useShareAPI(canvas) {
      canvas.toBlob((blob) => {
        const data = {
          files: [
            new File([blob], `${this.orderNumber}.png`, {
              type: blob.type,
              lastModified: new Date().getTime()
            })
          ]
        }

        if (!navigator.canShare(data))
          throw new Error("Can't share data.", data)
        navigator.share(data)
      })
    }
  }
}
