import UserInfoCard from '../../components/dashboard/user-info-card.vue'
import ResourceLinks from '../../components/dashboard/resource-links.vue'
import NotificationUnit from '../../components/notification/unit.vue'
import OrderFilters from '../../components/dashboard/order-filters.vue'
import OrderSummaryUnit from '../../components/order/summary-unit.vue'
import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'

export default {
  name: 'user-dashboard-container',

  components: {
    UserInfoCard,
    ResourceLinks,
    NotificationUnit,
    OrderFilters,
    OrderSummaryUnit
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      recentOrderIds: [],
      cartService: new ShoppingCartService(this.$vlf, this.$store)
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    recentOrders() {
      return this.recentOrderIds.map((id) =>
        this.$store.getters['orders/find'](id)
      )
    },

    hasRecentOrders() {
      return this.recentOrders.length > 0
    }
  },

  created() {
    this.$store
      .dispatch('users/fetchRecentOrders', {
        sort: '-completed_at'
      })
      .then((response) => {
        this.recentOrderIds = response.data.data.map((order) => order.id)
      })
  }

  // mounted() {},

  // methods: {}
}
