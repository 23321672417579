<template lang="pug">
.vc-order-cancel-by-recipient-form
  .form-header
    .messages {{ copyLocaleText('please_enter_recipient_info') }}
  .form-body
    .form-section
      b-field(
        :label="attributeLocaleText('order/address', 'recipient')"
        :type="errors.errorClassAt('recipient')"
        :message="errors.get('recipient')"
      )
        b-input(
          v-model="recipient"
          @input="errors.clear('recipient')"
        )
      b-field(
        :label="attributeLocaleText('order/address', 'phone')"
        :type="errors.errorClassAt('phone')"
        :message="errors.get('phone')"
      )
        b-input(
          v-model="phone"
          @input="errors.clear('phone')"
        )
  .form-footer
    .button.is-odd.is-block(
      @click="cancelOrder"
      :class="{ 'is-loading': isLoading }"
    ) {{ actionLocaleText('cancel_order') }}
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n, useInstance, useStore } from 'skid-composables'

const props = defineProps({
  jwtToken: {
    type: String,
    required: true
  }
})

const recipient = ref('')
const phone = ref('')

const store = useStore()
const instance = useInstance()
const I18n = useI18n()

/**
 * @returns {computedRef<boolean>}
 */
const isLoading = computed(() => {
  return store.getters['orders/isLoading']
})

/**
 * @returns {computedRef<Object>}
 */
const errors = computed(() => {
  return store.getters['orders/errors']
})

/**
 * @returns {Promise<void>}
 */
const cancelOrder = async () => {
  await store
    .dispatch('orders/cancelByRecipientInfo', {
      recipient: recipient,
      phone: phone,
      token: props.jwtToken
    })
    .then(() => {
      return instance.$emit('order-canceled')
    })
    .then(() => {
      return store.dispatch('addFlashMessage', [
        'success',
        I18n.messageLocaleText('order_canceled_successfully')
      ])
    })
    .then(() => {
      // @ts-ignore
      instance.$parent.close()
    })
}
</script>
