<template lang="pug">
.vc-blog-category-hot-section
  .blog-section-header.-border-bottom
    h2.section-title.-justify-left {{ sectionTitle }}
    .tools-wrapper

  .blog-section-body
    b-loading(
      :active.sync="isLoading"
      :is-full-page="false"
    )

    .blog-list
      blog-card(
        v-for="(blog, index) in blogs"
        :blog="blog"
        :index="index"
        :key="blog.id"
      )
</template>

<script>
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard
  },

  // mixins: [],

  props: {
    category: {
      type: Object,
      required: false
    },

    blogsCount: {
      type: Number,
      required: false,
      default: () => {
        return 3
      }
    }
  },

  data() {
    return {
      blogIds: null,
      isLoading: true
    }
  },

  computed: {
    sectionTitle() {
      return this.copyLocaleText('hot_blogs')
    },

    blogs() {
      if (this.blogIds)
        return this.$store.getters['promotionalArticles/all'].filter((blog) =>
          this.blogIds.includes(blog.id)
        )
    }
  },

  created() {
    this._fetchArticlesByCategory()
  },

  // mounted() {},

  methods: {
    _fetchArticlesByCategory() {
      this.$store
        .dispatch('categorySystems/fetchCategoryPromotionalArticles', {
          model: this.category,
          options: {
            pageNumber: 1,
            pageSize: this.blogsCount,
            sort: '-view_count',
            noReplace: true,
            sub_categories_included: true
          }
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
          this.isLoading = false
        })
    }
  }
}
</script>
