import SlideshowContainer from '../../../application/js/components/common/slideshow-container.vue'
import InfoLinkUseTypeContainer from '../components/info_link/use-type-container.vue'
import HotArticles from '../components/blog/hot-articles.vue'
import BlogSection from '../components/blog_category/blog-section.vue'
import BlogCategoryBasicMixins from '../components/mixins/blog_category_basic_mixin.js'
import HotBlogs from '../components/blog/hot-blogs.vue'

export default {
  name: 'blog-page-container',

  components: {
    SlideshowContainer,
    InfoLinkUseTypeContainer,
    HotArticles,
    BlogSection,
    HotBlogs
  },

  mixins: [BlogCategoryBasicMixins],

  // props: {},

  data() {
    return {
      availableCategoryFilters: ['寶寶成長', '幼兒教養', '家庭生活']
    }
  },

  computed: {
    availableCategories() {
      if (this.categories)
        return this.categories.filter((category) =>
          this.availableCategoryFilters.includes(category.name)
        )
    }
  }

  // created() {},

  // mounted() {},

  // methods: {}
}
