import { swiper, swiperSlide } from 'vue-awesome-swiper'
import img001 from '../../images/new_year_2020/001.jpg'
import img001Mobile from '../../images/new_year_2020/001-mobile.jpg'
import img002 from '../../images/new_year_2020/002.jpg'
import img002Mobile from '../../images/new_year_2020/002-mobile.jpg'
import img003 from '../../images/new_year_2020/003.jpg'
import img003Mobile from '../../images/new_year_2020/003-mobile.jpg'
import img004 from '../../images/new_year_2020/004.jpg'
import img004Mobile from '../../images/new_year_2020/004-mobile.jpg'

const MOBILE_IMAGE_BREAK_POINT = 429

export default {
  name: 'new-year-2020-container',

  components: {
    swiper,
    swiperSlide
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      banners: [
        {
          id: '1',
          image: {
            url: img001
          },
          mobile_image: {
            url: img001Mobile
          },
          alt_text: '歲末撿便宜 - 主視覺',
          url: 'https://inps.cc/bDiBq'
        },
        {
          id: '2',
          image: {
            url: img002
          },
          mobile_image: {
            url: img002Mobile
          },
          alt_text: '歲末撿便宜 - 揹巾區',
          url: 'https://inps.cc/hWGmG'
        },
        {
          id: '3',
          image: {
            url: img003
          },
          mobile_image: {
            url: img003Mobile
          },
          alt_text: '歲末撿便宜 - 育兒好物區',
          url: 'https://inps.cc/M5Bwq'
        },
        {
          id: '4',
          image: {
            url: img004
          },
          mobile_image: {
            url: img004Mobile
          },
          alt_text: '歲末撿便宜 - 睡眠安撫區',
          url: 'https://inps.cc/Za6hI'
        }
      ],
      swiperOption: {
        preventClicks: false,
        effect: 'fade',
        grabCursor: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    }
  }

  // created() {},

  // mounted() {},

  // methods: {}
}
