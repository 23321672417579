import SlideshowContainer from '../components/common/slideshow-container.vue'
// import OnSaleProducts from '../components/product/on-sale-products.vue'
import RandomSalesEvents from '../components/sales_event/random-events.vue'
import HotArticles from '../components/promotional_article/hot-articles.vue'
import BlogSection from '../components/blog_category/blog-section.vue'
import CategorySelectProducts from '../components/product_category/select-products.vue'
import hotProducts from '../components/product/hot-products.vue'
import VideoCard from '../components/brand/video-card.vue'
import BrandCard from '../components/brand/card.vue'

export default {
  name: 'landing-page-container',

  components: {
    SlideshowContainer,
    // OnSaleProducts,
    HotArticles,
    BlogSection,
    RandomSalesEvents,
    hotProducts,
    CategorySelectProducts,
    VideoCard,
    BrandCard
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      email: null,
      signedUp: false
    }
  },

  computed: {
    displayConfig() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig'].page_index ||
        {}
      )
    },

    randomBrands() {
      return this.$store.getters['brands/all']
    }
  },

  created() {
    this.$store.dispatch('brands/randomBrands').then(() => {
      setTimeout(() => {
        this.showBanner()
      }, 3000)
    })
  },

  // mounted() {},

  methods: {
    showBanner() {
      if (this.$el.dataset.email) {
        this.email = this.$el.dataset.email
        this.signedUp = true
      }
    }
  }
}
