<template lang="pug">
.vc-order-number-and-placed-date
  .order-number
    span.title {{ `${modelNameLocaleText('order')}${attributeLocaleText('order', 'number')}` }}
    span {{ order.number }}
  .completed-at
    b-tooltip(
      :label="dateTimeLocaleText(order.completed_at)"
      position="is-left"
      type="is-dark"
      size="is-small"
    )
      span {{ timeAgoLocaleText(order.completed_at) }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
