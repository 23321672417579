const PROMOTER_SHARE_KEY = 'promoter/share'

export default class PromoterEventStorageService {
  constructor(localForage) {
    this.localForage = localForage
  }

  savePromoterShareToken(promoterShareToken) {
    return this.localForage.setItem(PROMOTER_SHARE_KEY, promoterShareToken)
  }

  getPromoterShareToken() {
    return this.localForage.getItem(PROMOTER_SHARE_KEY)
  }

  clearPromoterShareToken() {
    return this.localForage.removeItem(PROMOTER_SHARE_KEY)
  }
}
