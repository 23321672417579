<template lang="pug">
.vc-promoter-event-check-order-query-form
  .odd-container.has-padding
    .form-wrapper
      .form-header
        h2.title
          | 訂單查詢
      .form-main
        b-field(
          :type="errors.errorClassAt('phone')"
          :message="errors.get('phone')"
        )
          b-input(
            type="text"
            placeholder="輸入收件人電話"
            @input="errors.clear('phone')"
            v-model="form.phone"
          )
        b-field(
          :type="errors.errorClassAt('email')"
          :message="errors.get('email')"
        )
          b-input(
            type="text"
            placeholder="輸入收件人email"
            @input=""
            v-model="form.email"
          )
        button.button.is-block.is-odd.login-button(@click="submit")
          | 查詢
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: {
    promoterShare: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        promoterShare: this.promoterShare
      }
    }
  },

  computed: {
    errors() {
      return this.form.promoterShare.errors
    }
  },

  // created() {},

  mounted() {},

  methods: {
    submit() {
      this.$store
        .dispatch('promoterShares/checkOrder', {
          model: this.promoterShare,
          options: {
            phone: this.form.phone,
            email: this.form.email
          }
        })
        .then((response) => {
          let orderIds = response.data.data.map((order) => {
            return order.id
          })

          this.$emit('form-submitted', orderIds)
        })
        .catch((response) => {})
    }
  }
}
</script>
