<template lang="pug">
.vc-return-authorization-date-and-number
  .order-number
    span.title {{ attributeLocaleText('return_authorization', 'number') }}
    span {{ returnAuthorization.number }}
    span(v-if="returnAuthorization.isApplyByAdmin") &nbsp;[{{ attributeLocaleText('return_authorization', 'is_apply_by_admin') }}]
  .completed-at
    b-tooltip(
      :label="dateTimeLocaleText(returnAuthorization.created_at)"
      position="is-left"
      type="is-dark"
      size="is-small"
    )
      span {{ timeAgoLocaleText(returnAuthorization.created_at) }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
