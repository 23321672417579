import SalesEvent from '../../resource_models/sales_event'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new SalesEvent(state.entities[id]))
}

export const find = (state) => (id) => {
  return new SalesEvent(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
