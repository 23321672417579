<template lang="pug">
section.vc-product-hot-products
  .section-header
    .odd-container.has-padding
      h2.section-title
        .icon
          img(:src="icon")
        .en hot
        .zh {{ copyLocaleText('hot_goods') }}
      //- .tools-wrapper
  .section-body
    .odd-container.has-padding
      b-loading(
        :active.sync="isLoading"
        :is-full-page="false"
      )

      product-card(
        v-for="(product, index) in hotProducts"
        :key="'hot' + product.id"
        :product="product"
        :index="index"
        :list-location="listLocation"
      )

      .options-wrapper
        a.button.is-odd(href="/products")
          span
            | {{ actionLocaleText('more', { model: modelNameLocaleText('product') }) }}
          .icon
            i.fa.fa-chevron-right
</template>

<script>
import fireIcon from '../../../images/fire.svg'
import ProductCard from './card.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    ProductCard
  },
  // mixins: [],
  props: {
    productsCount: {
      type: Number,
      required: false,
      default: () => {
        return 8
      }
    }
  },

  data() {
    return {
      hotProductIds: [],
      isLoading: true
    }
  },

  computed: {
    topProductIds() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig'].page_index
          ?.hot_product_ids || []
      )
    },

    hotProducts() {
      return this.hotProductIds.map((id) =>
        this.$store.getters['products/find'](id)
      )
    },

    icon() {
      return fireIcon
    },

    listLocation() {
      return 'Hot Products'
    }
  },

  async created() {
    if (this.topProductIds.length > 0) {
      const topProductResponse = await this.fetchTopProducts()
      let ids = topProductResponse.data.data.map((product) => product.id)
      this.hotProductIds = this.topProductIds.filter((id) => ids.includes(id))
    }
    if (this.productsCount > this.hotProductIds.length) {
      const hotProductResponse = await this.fetchHopProducts()
      hotProductResponse.data.data.map((product) =>
        this.hotProductIds.push(product.id)
      )
    }

    this.isLoading = false

    await this.$store.dispatch('ga4Operation', [
      generateGA4Events('view_item_list', {
        items: this.hotProducts,
        item_list_name: 'Home Page -> Hot Products'
      })
    ])
  },
  // mounted() {},
  methods: {
    fetchTopProducts() {
      return this.$store.dispatch('products/all', {
        forSearch: true,
        pageNumber: 1,
        pageSize: this.topProductIds.length,
        search: {
          id_in: this.topProductIds
        }
      })
    },

    fetchHopProducts() {
      return this.$store.dispatch('products/hotProducts', {
        pageNumber: 1,
        pageSize: this.productsCount - this.hotProductIds.length,
        sort: 'created_at',
        search: {
          id_not_in: this.hotProductIds
        }
      })
    }
  }
}
</script>
