import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'products',
  attributes: [
    'appreciation_period',
    'brand_id',
    'brand_name',
    'categories',
    'category_ids',
    'consumer_price',
    'cover',
    'created_at',
    'depth',
    'description',
    'discount_rate',
    'discounted_price',
    'height',
    'id',
    'images',
    'is_available',
    'is_preorder',
    'is_sold_out',
    'master',
    'meta_description',
    'meta_keywords',
    'meta_title',
    'name',
    'options',
    'original_price',
    'properties',
    'safe_stock_throttle',
    'sell_price',
    'shipping_category_id',
    'sku',
    'slug',
    'support_document_detail',
    'support_documents',
    'total_stock',
    'updated_at',
    'variants',
    'weight',
    'width',
    'is_tax_free',
    'stories'
  ],
  editableAttributes: [
    'appreciation_period',
    'brand_id',
    'category_ids',
    'depth',
    'description',
    'height',
    'is_available',
    'is_preorder',
    'meta_description',
    'meta_keywords',
    'meta_title',
    'name',
    'option_types',
    'price',
    'properties',
    'safe_stock_throttle',
    'shipping_category_id',
    'sku',
    'slug',
    'support_document_detail',
    'uploaded_attachment_ids',
    'weight',
    'width',
    'duplicate_variants',
    'is_tax_free',
    'stories'
  ]
}

export default class Product extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static onSaleProducts(options) {
    return axios.get(
      `${new this().apiBasePath()}/on_sale?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  static hotProducts(options) {
    return axios.get(
      `${new this().apiBasePath()}/hot?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  static uploadImages(formData) {
    return axios.post(`${new this().apiBasePath()}/images`, formData)
  }

  static uploadAttachments(formData) {
    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/editor_attachments`,
      formData
    )
  }

  static bulkDuplicate(formData) {
    return axios.post(`${new this().apiBasePath()}/bulk_duplicate`, formData)
  }

  fetchSupportDocuments() {
    return axios.get(`${this.apiBasePath()}/${this.id}/support_documents`)
  }

  fetchDistributionBases(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/distribution_bases?${FetchingDataOptionsService.call(options)}`
    )
  }

  uploadSupportDocument(formData) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/support_documents`,
      formData
    )
  }

  collect() {
    return axios.post(`${this.apiBasePath()}/${this.id}/collect`)
  }

  uncollect() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/collect`)
  }

  duplicate() {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/duplicate`,
      this.requestBody()
    )
  }

  // helpers

  static get sortableFields() {
    return ['created_at', 'name', 'master.sell_price_cents']
  }

  hasDiscount() {
    return this.discount_rate < 1
  }

  requestBody() {
    const priceColumns = ['original', 'sell', 'discounted']

    // Price columns must be [Number]
    priceColumns.forEach((element) => {
      this.price[element] = parseInt(this.price[element])
      if (this.duplicate_variants && this.duplicate_variants instanceof Array) {
        this.duplicate_variants.forEach((variant) => {
          variant.price[element] = parseInt(variant.price[element])
        })
      }
    })

    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: this.attributes()
      }
    }
  }

  /**
   * 取得折數。
   *
   * @param {boolean} [options={
   *     withUnit: true,
   *   }] 若 options.withUnit 為 true，回傳 `85 折`；否則回傳 `85`
   * @returns
   */
  displayDiscountRate(
    options = {
      withUnit: true
    }
  ) {
    let rateNumber = this.discount_rate.toFixed(2).toString().split('.')[1]

    if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)
    if (options.withUnit)
      rateNumber += ` ${I18n.t(
        'activerecord.attributes.product.discount_unit'
      )}`

    return rateNumber
  }

  displaySize() {
    const properties = ['width', 'depth', 'height']
    let result = []

    properties.forEach((property) => {
      if (this[property])
        result.push(
          `${I18n.t(`activerecord.attributes.product.${property}`)} ${
            this[property]
          }`
        )
    })

    return result.join(' × ')
  }

  hasVariants() {
    return this.variants.length > 0
  }

  coverImageThumb() {
    return this.cover.square.url
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  toggleIsTaxFree() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_is_tax_free`)
  }
}
