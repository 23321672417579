<template lang="pug">
.vc-info-link-info-box
  .inner
    a.image-wrapper(
      :href="infoLink.url"
      target="_blank"
      ref="noopenner noreferrer"
    )
      img.image(v-lazy="infoLink.cover.url")

      .info-wrapper
        .info-title
          | {{ infoLink.title }}
        .info-sub-title
          | {{ infoLink.sub_title || '&nbsp;' }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    infoLink: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
