<template lang="pug">
.vc-blog-card
  .inner
    a.blog-image(:href="blogLink")
      img.image(v-lazy="coverSrc")
    .blog-info
      //- .blog-time
      //-   span {{ dateTimeLocaleText(blog.published_at, format = 'YYYY MMMDo') }}
      .blog-title
        a.inner-wrapper(:href="blogLink")
          h4.title {{ blog.title }}
      .blog-description
        .description {{ blog.description() }}
      .options-wrapper
        .option-button
          a.button.is-default(:href="blogLink")
            span.text {{ copyLocaleText('read_blog') }}
</template>

<script>
const MOBILE_IMAGE_BREAK_POINT = 429

export default {
  // components: {},
  // mixins: [],
  props: {
    blog: {
      type: Object,
      required: true
    },

    useBlogLink: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    blogLink() {
      if (this.useBlogLink) return `/babynews/${this.blog.id}/${this.blog.slug}`

      return `/promotional-articles/${this.blog.id}`
    },

    coverSrc() {
      return this.isMobile
        ? this.blog.mobile_cover.url || this.blog.cover.url
        : this.blog.cover.url
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
