<template lang="pug">
.vc-comment-unit(:class="{ 'is-reply': comment.is_reply }")
  .creator-info
    .avatar
      img(:src="comment.avatarUrl()")
    .name
      span {{ dispalyName }}
  .comment-content
    div(v-html="simpleFormat(comment.content)")
    .created-at(:title="createdOrEditedOnDatetime") {{ createdOrEditedOnText }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    dispalyName() {
      if (this.comment.is_reply) {
        return this.copyLocaleText('service_center')
      } else {
        return !!this.comment.commenter['id']
          ? this.modelNameLocaleText('user')
          : this.modelNameLocaleText('null/user')
      }
    },

    createdOrEditedOnText() {
      if (this.comment.isEdited()) {
        return this.messageLocaleText('edited_on', {
          datetime: this.timeAgoLocaleText(this.comment.updated_at)
        })
      } else {
        return this.timeAgoLocaleText(this.comment.created_at)
      }
    },

    createdOrEditedOnDatetime() {
      if (this.comment.isEdited()) {
        return this.dateTimeLocaleText(this.comment.updated_at)
      } else {
        return this.dateTimeLocaleText(this.comment.created_at)
      }
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
