<template lang="pug">
.vc-order-shipment-detail
  h3.index-number {{ modelNameLocaleText('order/shipment') }} {{ shipmentIndex }}
  .inner-wrapper
    .basic-info
      .address
        .group.basic
          .info-unit
            .label {{ attributeLocaleText('order/address', 'recipient') }}
            .info-content {{ address.recipient }}
          .info-unit
            .label {{ attributeLocaleText('order/address', 'phone') }}
            .info-content {{ address.phone }}
        template(v-if="address.hasCompanyInfo()")
          .group.company
            .info-unit
              .label {{ attributeLocaleText('order/address', 'company') }}
              .info-content {{ address.company }}
            .info-unit
              .label {{ attributeLocaleText('order/address', 'ein') }}
              .info-content {{ address.ein }}
        .info-unit
          .label {{ attributeLocaleText('order/address', 'address') }}
          .info-content {{ address.fullAddress() }}
      .shipment-method-and-tracking-code
        .info-unit
          .label {{ attributeLocaleText('order/shipment', 'shipping_method_id') }}
          .info-content
            .tag.is-info {{ shipment.gateway_info.method_name }}
        .info-unit
          .label {{ attributeLocaleText('order/shipment', 'tracking_code') }}
          .info-content
            b {{ shipment.tracking_code }}

    .state-flow
      .flow-chart(:class="stateInfo.step")
        .progress-wrapper
          .progress
        .steps-indicator
          .step
            span.tag(
              v-if="['pending'].includes(shipment.state)"
              :class="shipment.stateType()"
            ) {{ aasmStateLocaleText('order/shipment', 'state', shipment.state) }}
          .step
            span.tag(
              v-if="['ready'].includes(shipment.state)"
              :class="shipment.stateType()"
            ) {{ aasmStateLocaleText('order/shipment', 'state', shipment.state) }}
          .step
            span.tag(
              v-if="['tallying'].includes(shipment.state)"
              :class="shipment.stateType()"
            ) {{ aasmStateLocaleText('order/shipment', 'state', shipment.state) }}
          .step
            span.tag(
              v-if="['shipped', 'canceled'].includes(shipment.state)"
              :class="shipment.stateType()"
            ) {{ aasmStateLocaleText('order/shipment', 'state', shipment.state) }}
      .state-info
        .info-unit
        .info-unit
          b {{ stateInfo.label }}:&nbsp;
          span {{ stateInfo.info }}

      .shipment-items
        template(v-if="shouldListShipmentItem")
          .select-all-button.button.is-small.is-warning(
            v-if="isEditable"
            @click="toggleAllItemCheckboxValues"
          )
            .icon
              i.fa.fa-check-square-o.fa-lg
            span {{ actionLocaleText('select_all_x', { x: modelNameLocaleText('order/shipment_item') }) }}

          shipment-item-unit(
            v-for="item in shipment.shipment_items"
            :item="item"
            :form="form"
            :key="item.id"
            ref="shipmentItemUnit"
          )
        template(v-else)
          b-message(
            :title="messageLocaleText('over_returnable_period')"
            type="is-info"
            :closable="false"
          )
            span {{ messageLocaleText('returnable_limit_datetime_is_x', { x: dateTimeLocaleText(shipment.returnable_limit_datetime, 'YYYY-MM-DD') }) }}
</template>

<script>
import UserCommonAddress from '../../../../shared/resource_models/user_common_address.js'
import ShipmentItemUnit from './item-unit.vue'

export default {
  components: {
    ShipmentItemUnit
  },
  // mixins: [],
  props: {
    shipment: {
      type: Object,
      required: true
    },

    form: {
      type: Object,
      required: false
    },

    index: {
      type: Number,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isEditable() {
      // if this.form is an object that pass in via props
      return !!this.form
    },

    hasMultipleShipments() {
      return this.$store.getters['orderShipments/all'].length > 1
    },

    shipmentIndex() {
      if (!this.hasMultipleShipments) return null

      return this.index + 1
    },

    address() {
      return new UserCommonAddress(this.shipment.address)
    },

    stateInfo() {
      switch (this.shipment.state) {
        case 'pending':
          return {
            step: 'step-1',
            label: this.attributeLocaleText('order/shipment', 'created_at'),
            info: this.dateTimeLocaleText(this.shipment.created_at)
          }
        case 'ready':
          return {
            step: 'step-2',
            label: this.attributeLocaleText('order/shipment', 'updated_at'),
            info: this.dateTimeLocaleText(this.shipment.updated_at)
          }
        case 'tallying':
          return {
            step: 'step-3',
            label: this.attributeLocaleText('order/shipment', 'tallied_at'),
            info: this.dateTimeLocaleText(this.shipment.tallied_at)
          }
        case 'shipped':
          return {
            step: 'step-4',
            label: this.attributeLocaleText('order/shipment', 'shipped_at'),
            info: this.dateTimeLocaleText(this.shipment.shipped_at)
          }
        case 'canceled':
          return {
            step: 'step-4',
            label: this.attributeLocaleText('order/shipment', 'updated_at'),
            info: this.dateTimeLocaleText(this.shipment.updated_at)
          }
      }
    },

    /**
     * 是否需要顯示 shipment items.
     * 當沒有 form 被傳入時，視為顯示模式，應該一律列出 shipment items 供使用者檢視。
     * 當有 form 被傳入時，表示這個 component 正被用在 RMA apply wizard，需視出貨狀況決定是否顯示 shipment items。
     * @returns {boolean}
     */
    shouldListShipmentItem() {
      return !this.form || this.shipment.canApplyForRma()
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    toggleAllItemCheckboxValues() {
      const shipmentItemComponents = this.$refs['shipmentItemUnit'].filter(
        (unit) => unit.rmaApplicable
      )

      if (
        shipmentItemComponents
          .map((component) => component.isItemSelected)
          .every((value) => value)
      ) {
        shipmentItemComponents.map((component) =>
          component.changeCheckboxValueTo(false)
        )
      } else {
        shipmentItemComponents.map((component) =>
          component.changeCheckboxValueTo(true)
        )
      }
    }
  }
}
</script>
