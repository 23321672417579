import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import CategoryMenu from '../../components/content_category/category-menu.vue'
import SlideshowContainer from '../../components/common/slideshow-container.vue'
import OddPagination from '../../components/common/odd-pagination.vue'

export default {
  name: 'contents-index-page-container',

  components: {
    CategoryMenu,
    SlideshowContainer,
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      isMobileMenuShow: false,
      isInitializing: false,
      resourceType: 'contents',
      currentPage: 1,
      pageSize: 9,
      sortOrder: 'desc',
      sortField: 'published_at',
      currentFilter: 0,
      availableFilters: [this.useType],
      isSearchOptionsOpen: true,
      searchOptions: {
        name_cont: null
      },
      currentCategory: undefined,
      currentSubCategory: undefined,
      selectedCategoryId: undefined
    }
  },
  computed: {
    isCategorySelected() {
      return this.selectedCategoryId !== undefined
    },

    sectionTitle() {
      if (this.isCategorySelected) {
        let result = this.currentCategory.name
        if (this.currentSubCategory)
          result += ` / ${this.currentSubCategory.name}`
        return result
      } else {
        return this.actionLocaleText('all', {
          model: this.modelNameLocaleText('content')
        })
      }
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },

    allCategories() {
      return this.$store.getters['categorySystems/allCategories']()
    },

    contents() {
      return this.$store.getters['contents/all']
    },

    hasContent() {
      return this.contents.length > 0
    },

    additionalOptions() {
      return {
        category: this.selectedCategoryId
      }
    }
  },

  watch: {
    currentCategory: {
      handler(newValue) {
        if (newValue === undefined) return (this.selectedCategoryId = undefined)
        if (newValue && !this.currentSubCategory) {
          this.selectedCategoryChangedHandler(newValue)
        }
      }
    },

    currentSubCategory: {
      handler(newValue) {
        this.selectedCategoryChangedHandler(newValue)
      }
    },

    selectedCategoryId: {
      handler(newValue, oldValue) {
        if (newValue == oldValue) return
        if (newValue === undefined) return this._backToAllContentsMode()
        // 如果 `isInitializing === true`，處正在初始化中，直接中斷並結束初始化狀態。
        // isInitializing 會在 categoriesLoadedHandler 被觸發時設定為 true。但若 URL 中不包含指定 category 的 params 則會在結束時被設定為 false
        if (this.isInitializing) {
          return (this.isInitializing = false)
        }

        this.currentPage = 1
        this.fetchData(this.currentOptions)
        this.updateQueryString(this.currentOptions)
      }
    }
  },

  // created() {},

  // mounted() {},

  methods: {
    visitContent(content) {
      Turbolinks.visit(`/contents/${content.slug || content.id}`)
    },

    checkAdditionalOptionsFromUrl() {
      return {
        category: this.currentQueryString['category'] || this.selectedCategoryId
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      if (options.category) this.selectedCategoryId = parseInt(options.category)
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.category) result += `&category=${options.category}`

      return result
    },

    openMobileMenu() {
      this.isMobileMenuShow = true
      // this.isMobileMenuShow = !this.isMobileMenuShow
    },

    categoriesLoadedHandler() {
      this.isInitializing = true
      this.fetchingInitialData()
      this._initializeStateFromQueryString()
    },

    selectedCategoryChangedHandler(newCategory) {
      if (newCategory) {
        // 若新的值不是 undefined（是 contentCategory instance）則將 selectedCategoryId 重新賦值為 nerCategory.id
        this.selectedCategoryId = newCategory.id
      } else {
        // 若新的值是 undefined，已有選擇主類別的狀況下，將 selectedCategoryId 設定為當前主類別 ID，若沒有選擇的話則設定為 undefined
        this.selectedCategoryId = this.currentCategory
          ? this.currentCategory.id
          : undefined
      }
    },

    sortOptionUpdatedHandler(field, order) {
      this.onSort(field, order)
    },

    searchOptionUpdatedHandler(string) {
      this.searchOptions.name_cont = string
      this.fetchData(this.currentOptions).then(() => {
        if (this.currentPage === 0) this.currentPage = 1
        this.updateQueryString(this.currentOptions)
      })
    },

    fetchData(options) {
      this.currentPage = options.pageNumber
      if (this.selectedCategoryId) {
        return this._fetchCategoryContents(options)
      } else {
        return this._fetchContents(options)
      }
    },

    _initializeStateFromQueryString() {
      if (!this.selectedCategoryId) return

      if (this.selectedCategoryId) {
        const newCategory = this.allCategories.find(
          (category) => category.id === String(this.selectedCategoryId)
        )

        if (newCategory.isDataLoaded()) {
          this._assignCategoryToDataAccordingTo(newCategory)
        }
      } else {
        this.isInitializing = false
      }
    },

    _fetchCategoryContents(options) {
      return this.$store.dispatch(`categorySystems/fetchCategoryContents`, {
        model: this.$store.getters['categorySystems/findCategory'](
          this.selectedCategoryId
        ),
        options: Object.assign(options, {
          sub_categories_included: true
        })
      })
    },

    _fetchContents(options) {
      return this.$store.dispatch(
        'contents/all',
        Object.assign(options, {
          replace: true,
          filter: this.useType
        })
      )
    },

    _backToAllContentsMode() {
      this.currentPage = 1
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
    },

    _assignCategoryToDataAccordingTo(newCategory) {
      if (newCategory.depth === 3) {
        this.currentSubCategory = newCategory
        this.currentCategory = this.allCategories.find(
          (category) => category.id === String(newCategory.parent_id)
        )
      } else {
        this.currentCategory = newCategory
        this.currentSubCategory = null
      }
    }
  }
}
