import * as types from './mutation-types'
import CategorySystem from '../../resource_models/category_system'
import Category from '../../resource_models/category'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    CategorySystem.all(options)
      .then((response) => {
        commit(types.FETCH_CATEGORY_SYSTEMS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    CategorySystem.find(id)
      .then((response) => {
        commit(types.GET_CATEGORY_SYSTEM_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchCategoriesByType = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'fetchCategoriesByType')

  return new Promise((resolve, reject) => {
    Category.byType(options)
      .then((response) => {
        if (options.noReplace) {
          commit(types.GET_RELATED_CATEGORIES_SUCCESS, response)
        } else {
          commit(types.FETCH_CATEGORIES_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCategoriesByType,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchCategoriesByUsage = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'fetchCategoriesByType')

  return new Promise((resolve, reject) => {
    Category.byUsage(options)
      .then((response) => {
        commit(types.FETCH_CATEGORIES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCategoriesByType,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const saveCategory = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'saveCategory')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_CATEGORY_SUCCESS, response)
        } else {
          commit(types.UPDATE_CATEGORY_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveCategory,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyCategory = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroyCategory')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_CATEGORY_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyCategory,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchCategoryContents = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'fetchCategoryContents')

  return new Promise((resolve, reject) => {
    model
      .fetchContents(options)
      .then((response) => {
        dispatch(
          'contents/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchContents')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCategoryContents,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchCategorySalesEvents = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'fetchCategorySalesEvents')

  return new Promise((resolve, reject) => {
    model
      .fetchSalesEvents(options)
      .then((response) => {
        dispatch(
          'salesEvents/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchSalesEvents')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCategorySalesEvents,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
export const fetchCategoryPromotionalArticles = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'fetchCategoryPromotionalArticles')

  return new Promise((resolve, reject) => {
    model
      .fetchPromotionalArticles(options)
      .then((response) => {
        if (options.noReplace) {
          dispatch(
            'promotionalArticles/receiveResourcesFromRelationships',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch(
            'promotionalArticles/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        }

        commit(types.API_REQUEST_SUCCESS, 'fetchPromotionalArticles')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCategoryPromotionalArticles,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_CATEGORY_SYSTEMS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_CATEGORY_SYSTEM_SUCCESS, response)

    resolve(response)
  })
}

export const getCategoryFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.ADD_CATEGORY_SUCCESS, response)

    resolve(response)
  })
}

export const receiveCategoriesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_CATEGORIES_SUCCESS, response)

    resolve(response)
  })
}
