<template lang="pug">
.vc-order-coupon-unit
  .name {{ coupon.name }}
  .delete-button(@click="removeCoupon")
    .icon
      i.fa.fa-close
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    coupon: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    removeCoupon() {
      this.$store.dispatch('users/removeCoupon', this.coupon.code).then((_) => {
        this.$store.dispatch('addFlashMessage', [
          'notice',
          this.messageLocaleText('resource_deleted_successfully', {
            resource: this.attributeLocaleText('order', 'coupon_code')
          })
        ])
      })
    }
  }
}
</script>
